import PATHS from '../paths';
import PaymentsPage from '../pages/payments/PaymentsPage';

export const PAYMENTS_ROUTES = [
  {
    index: true,
    element: <PaymentsPage />
  },
  {
    path: PATHS.PAYMENT_VIEW,
    element: <h1>Payment view</h1>
  }
];
