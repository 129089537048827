import { api } from '../../api/Api';
import ICollectionParams from '../../models/collections/ICollectionParams';
import { IFileCollection } from '../../models/collections/IFileCollection';

export const filesPerPageValues = [36, 72, 144];
export const defaultFilesCollection: IFileCollection = {
  perPage: filesPerPageValues[0],
  pageNo: 1,
  sortColumn: 'ID',
  sortDirection: '-',
  totalCount: 0,
  itemsCount: 0,
  items: []
};

const FilesLoader = (
  collectionParams: ICollectionParams = defaultFilesCollection
) =>
  api
    .getCollection('File', collectionParams)
    .then(resp => resp.itemsCollection as IFileCollection);

export default FilesLoader;
