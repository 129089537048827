import axios from 'axios';
import IDataObject from '../models/restful/IDataObject';
import IResponse from '../models/responces/IResponse';
import ICollectionParams from '../models/collections/ICollectionParams';
import { ICollectionResponse } from '../models/responces/ICollectionResponse';
import IMerchProductOrder from '../models/restful/IMerchProductOrder';
import IMerchProductOrderResponse from '../models/responces/IMerchProductOrderResponse';
import IMerchProductOrderItem from '../models/restful/IMerchProductOrderItem';
import { IFilesCollectionResponse } from '../models/responces/IFilesCollectionResponse';
import { AxiosRequestConfig } from 'axios';
import IUserResponse from '../models/responces/IUserResponse';
import IPayResponse from '../models/responces/IPayResponse';
import { useUser } from '../pages/orders/hooks/useUser';
import IFileResponse from '../models/responces/IFileResponse';
import ISubscriptionPlan from '../models/restful/ISubscriptionPlan';

const apiUrl = () => `${api.host}/api`;
const url = (
  dataObjectName: string,
  id?: number | string,
  handler = 'restful',
  field = 'ID'
) => {
  if (field === 'ID')
    return `${apiUrl()}/${handler}/${dataObjectName}` + (id ? `/${id}` : ``);
  return `${apiUrl()}/${handler}/${dataObjectName}?${field}=${id}`;
};

const collectionParamsToString = (collectionParams: ICollectionParams) => {
  const u = new URLSearchParams();
  for (const [key, value] of Object.entries(collectionParams)) {
    u.append(key, '' + value);
  }
  return u.toString();
};

export const api = {
  host: process.env.REACT_APP_API_URL,
  getToken: () => {
    const { getUser } = useUser();
    return getUser()?.RestfulToken;
  },
  signIn: (email: string, pass: string) =>
    axios({
      method: 'post',
      url: apiUrl() + '/auth/signin',
      data: { email: email, pass: pass },
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IUserResponse>,
  signUp: (
    firstName: string,
    email: string,
    pass: string,
    lastName?: string,
    promoCode?: string
  ) =>
    axios({
      method: 'post',
      url: apiUrl() + '/auth/signup',
      data: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        pass: pass,
        promoCode: promoCode
      },
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IUserResponse>,
  create: (dataObjectName: string, dataObject: IDataObject) =>
    axios({
      method: 'post',
      headers: { Authorization: api.getToken() },
      url: url(dataObjectName),
      data: dataObject,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  read: (dataObjectName: string, id: number | string, field = 'ID') =>
    axios({
      method: 'get',
      headers: { Authorization: api.getToken() },
      url: url(dataObjectName, id, 'restful', field),
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  update: (dataObjectName: string, dataObject: IDataObject) =>
    axios({
      method: 'put',
      headers: { Authorization: api.getToken() },
      url: url(dataObjectName, dataObject.ID),
      data: dataObject,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  delete: (
    dataObjectName: string,
    ids: number[],
    collectionParams?: ICollectionParams
  ) =>
    axios({
      method: 'delete',
      headers: { Authorization: api.getToken() },
      url: url(dataObjectName, ids.join(',')),
      data: collectionParams ? collectionParams : {},
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  getCollection: (
    dataObjectName: string,
    collectionParams: ICollectionParams
  ) =>
    axios({
      method: 'get',
      headers: { Authorization: api.getToken() },
      url: `${url(dataObjectName)}?${collectionParamsToString(
        collectionParams
      )}`,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<ICollectionResponse>,

  uploadFile: (file: File, cfg: AxiosRequestConfig = {}) => {
    const formData = new FormData();
    formData.append(`File`, file);
    return axios({
      ...cfg,
      method: 'post',
      headers: {
        Authorization: api.getToken(),
        'Content-Type': 'multipart/form-data'
      },
      url: url('File'),
      data: formData,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IFileResponse>;
  },
  upload: (
    dataObjectHolderName: string,
    dataObjectHolderID: number,
    relationName: string,
    files: File[],
    cfg: AxiosRequestConfig = {}
  ) => {
    const formData = new FormData();
    formData.append('relationName', relationName);
    files.forEach((file, idx) => formData.append(`File_${idx}`, file));
    return axios({
      ...cfg,
      method: 'post',
      headers: {
        Authorization: api.getToken(),
        'Content-Type': 'multipart/form-data'
      },
      url: url(dataObjectHolderName, dataObjectHolderID),
      data: formData,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IFilesCollectionResponse>;
  },

  createOrder: (order?: IMerchProductOrder) =>
    axios({
      method: 'post',
      headers: { Authorization: api.getToken() },
      url: url('MerchProductOrder', 0, 'order/create'),
      data: order,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IMerchProductOrderResponse>,

  duplicateOrder: (id: number) =>
    axios({
      method: 'post',
      headers: { Authorization: api.getToken() },
      url: url('MerchProductOrder', id, 'order/duplicate'),
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IMerchProductOrderResponse>,

  createOrderItem: (orderItem?: IMerchProductOrderItem) =>
    axios({
      method: 'post',
      headers: { Authorization: api.getToken() },
      url: url('MerchProductOrderItem', 0, 'order/createOrderItem'),
      data: orderItem,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IMerchProductOrderResponse>,

  deleteOrderItem: (orderItem?: IMerchProductOrderItem) =>
    axios({
      method: 'post',
      headers: { Authorization: api.getToken() },
      url: url('MerchProductOrderItem', 0, 'order/deleteOrderItem'),
      data: orderItem,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IMerchProductOrderResponse>,

  confirm: (orders: IMerchProductOrder[]) =>
    axios({
      method: 'post',
      headers: { Authorization: api.getToken() },
      url: url(
        'MerchProductOrder',
        orders.map(o => o.ID).join(','),
        'order/confirm'
      ),
      // data: ,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  pay: (orders: IMerchProductOrder[]) =>
    axios({
      method: 'post',
      headers: { Authorization: api.getToken() },
      url: url(
        'MerchProductOrder',
        orders.map(o => o.ID).join(','),
        'order/pay'
      ),
      // data: ,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IPayResponse>,
  payPlan: (plan: ISubscriptionPlan, options: { period: 'month' | 'year' }) =>
    axios({
      method: 'post',
      headers: { Authorization: api.getToken() },
      url: url('SubscriptionPlan', plan.ID, 'subscription/pay'),
      data: { ...plan, _options: options },
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IPayResponse>,

  transaction: (data: { hash: string; success: boolean }) =>
    axios({
      method: 'put',
      headers: { Authorization: api.getToken() },
      url: url('Transaction', 0, 'order/transaction'),
      data: data,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  updateOrders: (orderItem?: IMerchProductOrderItem) =>
    axios({
      method: 'post',
      headers: { Authorization: api.getToken() },
      url: url('MerchProductOrderItem', 0, 'order/updateOrders'),
      data: orderItem,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>
};
