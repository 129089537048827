import { api } from '../../api/Api';
import { ISubscriptionPlanCollection } from '../../models/collections/ISubscriptionPlanCollection';

export const defaultCollection = {
  pageNo: 1,
  perPage: 100,
  items: [],
  itemsCount: 0,
  totalCount: 0
} as ISubscriptionPlanCollection;

const SubscriptionPlansLoader = () =>
  api
    .getCollection('SubscriptionPlan', defaultCollection)
    .then(resp => resp.itemsCollection as ISubscriptionPlanCollection);

export default SubscriptionPlansLoader;
