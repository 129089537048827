import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import React from 'react';

interface ISearchBoxProps {
  placeholder?: string;
  onChange(text: string): void;
}

const SearchBox: React.FunctionComponent<ISearchBoxProps> = props => {
  return (
    <div className={'search-box'}>
      <div className="position-relative">
        <Form.Control
          type="search"
          placeholder={props.placeholder}
          className={'search-input search'}
          size="sm"
          onChange={e => props.onChange(e.target.value)}
        />
        <FontAwesomeIcon
          icon={faSearch}
          className="search-box-icon"
          onClick={e => console.log(e.target)}
        />
      </div>
    </div>
  );
};

export default SearchBox;
