import './Products.scss';
import React, { useEffect, useState } from 'react';
import { api } from '../../../../api/Api';
import Product from './Product/Product';
import Loader from '../../../../components/common/Loader/Loader';
import FormCollapse from '../../../../components/common/FormCollapse';
import IMerchProductOrder from '../../../../models/restful/IMerchProductOrder';
import IMerchProduct from '../../../../models/restful/IMerchProduct';
import { ICategoryCollection } from '../../../../models/collections/ICategoryCollection';
import IMerchCategory from '../../../../models/restful/IMerchCategory';
import { Form } from 'react-bootstrap';

interface IProductsProps {
  order: IMerchProductOrder;
  products: IMerchProduct[];
  selectedProducts?: IMerchProduct[];
  showRequired: boolean;
  setOrder(order: IMerchProductOrder): void;
  setSelectedProducts(products: IMerchProduct[]): void;
}

export let selectedProducts: IMerchProduct[] = [];

const Products: React.FunctionComponent<IProductsProps> = (
  props: IProductsProps
) => {
  const [categories, setCategories] = useState<IMerchCategory[]>([]);
  const [selectedCats, setSelectedCats] = useState<IMerchCategory[]>([]);
  const order = props.order;

  const updateFromServer = () => {
    api
      .getCollection('MerchCategory', { pageNo: 1, perPage: 200 })
      .then(resp => {
        const cats = (resp.itemsCollection as ICategoryCollection).items;
        setCategories(cats);
        const pids = order.ProductOrderItems.map(oi => oi.ProductID);
        selectedProducts.length = 0;
        cats.forEach(cat1 => {
          cat1.SubCategories.forEach(cat2 => {
            cat2.Products.forEach(p => {
              if (pids.includes(p.ID)) selectedProducts.push(p);
            });
          });
        });
        selectAllCats(true, cats);
      });
  };

  useEffect(() => {
    updateFromServer();
  }, []);

  let allProducts: IMerchProduct[] = [];
  selectedCats.forEach(cat => (allProducts = allProducts.concat(cat.Products)));

  const selectAllCats = (b: boolean, cats?: IMerchCategory[]) => {
    if (b) {
      const selCats: IMerchCategory[] = [];
      const cs = cats ? cats : categories;
      cs.forEach(cat => cat.SubCategories.forEach(cat1 => selCats.push(cat1)));
      setSelectedCats(selCats);
    } else setSelectedCats([]);
  };

  return (
    <div className="products-cont">
      {props.showRequired && selectedProducts.length === 0 && (
        <div className="alert alert-danger" role="alert">
          Select at least one product
        </div>
      )}
      {!categories.length && <Loader />}
      {categories.length && (
        <div className="row">
          <div className="col-3">
            <Form.Check
              type="checkbox"
              id="all_cats"
              className="mb-0 d-flex align-items-center gap-2"
              key="all_cats"
            >
              <Form.Check.Input
                type="checkbox"
                className="mt-0"
                defaultChecked={true}
                onChange={e => selectAllCats(e.target.checked)}
              />
              <Form.Check.Label className="d-block lh-sm fs-8 text-900 fw-normal mb-0">
                All products
              </Form.Check.Label>
            </Form.Check>
            {categories.length > 0 &&
              categories.map(cat1 => {
                return (
                  <FormCollapse title={cat1.Title} key={cat1.ID}>
                    {cat1.SubCategories.map(cat2 => (
                      <Form.Check
                        type="checkbox"
                        id={`cat_${cat2.ID}`}
                        className="mb-0 d-flex align-items-center gap-2"
                        key={cat2.ID}
                      >
                        <Form.Check.Input
                          type="checkbox"
                          className="mt-0"
                          checked={!!selectedCats.find(c => c.ID === cat2.ID)}
                          onChange={e => {
                            if (e.target.checked)
                              setSelectedCats([...selectedCats, cat2]);
                            else
                              setSelectedCats(
                                selectedCats.filter(ii => ii.ID !== cat2.ID)
                              );
                          }}
                        />
                        <Form.Check.Label className="d-block lh-sm fs-8 text-900 fw-normal mb-0">
                          {cat2.Title}
                        </Form.Check.Label>
                      </Form.Check>
                    ))}
                  </FormCollapse>
                );
              })}
          </div>
          <div className="col-9">
            <div className="row">
              {allProducts.length > 0 &&
                allProducts.map(product => {
                  return (
                    <Product
                      order={order}
                      product={product}
                      key={product.ID}
                      setOrder={props.setOrder}
                      setIsChecked={(product, checked) => {
                        selectedProducts = selectedProducts.filter(
                          p => p.ID !== product.ID
                        );
                        if (checked) selectedProducts.push(product);
                      }}
                    />
                  );
                })}
              {allProducts.length === 0 && <h3>Select a category</h3>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
