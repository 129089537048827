import OrderSummaryDetails from './OrderSummaryDetails';
import { Card } from 'react-bootstrap';
import IMerchProductOrder from '../../../models/restful/IMerchProductOrder';
import React from 'react';

interface IOrderDetailsSummaryCardProps {
  order: IMerchProductOrder;
  includeOrderItems?: boolean;
}
const OrderDetailsSummaryCard: React.FunctionComponent<
  IOrderDetailsSummaryCardProps
> = props => {
  const o = props.order;
  return (
    <Card>
      <Card.Body>
        <Card.Title as="h3" className="mb-4">
          Summary
        </Card.Title>

        {props.includeOrderItems &&
          props.order.ProductOrderItems.map(oi => {
            let src = '';
            const image = oi.Mockup ? oi.Mockup : oi.Artwork;
            if (image) {
              src = image._props?.thumb128;
            }
            return (
              <div className="row" key={oi.ID}>
                <div className="col-1">
                  <img src={src} alt="" style={{ width: '30px' }} />
                </div>
                <div className="col-6">
                  <strong>{oi.Title}</strong>
                </div>
                <div className="col-1">x {oi.Quantity}</div>
                <div className="col-4">
                  <div className="text-end">
                    {oi._props.properties.Price.nice}
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
        <div className="border-bottom border-dashed mb-4">
          <OrderSummaryDetails order={o} />
        </div>
        <div className="d-flex justify-content-between">
          <h4 className="mb-0">Total :</h4>
          <h4 className="mb-">{o._props.properties.Price.nice}</h4>
        </div>
      </Card.Body>
    </Card>
  );
};

export default OrderDetailsSummaryCard;
