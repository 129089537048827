import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import PATHS from '../../../paths';
import { api } from '../../../api/Api';
import { useUser } from '../../../pages/orders/hooks/useUser';
import { useState } from 'react';

const SignUpForm = () => {
  const { setUser } = useUser();
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  return (
    <>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <Form
        onSubmit={e => {
          e.stopPropagation();
          e.preventDefault();
          const firstNameInput = document.getElementById(
            'firstName'
          ) as HTMLInputElement;
          const lastNameInput = document.getElementById(
            'lastName'
          ) as HTMLInputElement;
          const emailInput = document.getElementById(
            'email'
          ) as HTMLInputElement;
          const promoCodeInput = document.getElementById(
            'promoCode'
          ) as HTMLInputElement;
          const passInput = document.getElementById(
            'password'
          ) as HTMLInputElement;
          const confirmPassInput = document.getElementById(
            'confirmPassword'
          ) as HTMLInputElement;
          if (passInput.value !== confirmPassInput.value) {
            setError('Confirm password must be the same as password');
            confirmPassInput.focus();
            return;
          }
          if (firstNameInput.value && emailInput.value && passInput.value) {
            api
              .signUp(
                firstNameInput.value,
                emailInput.value,
                passInput.value,
                lastNameInput.value,
                promoCodeInput.value
              )
              .then(userResponse => {
                if (userResponse.success) {
                  setUser(userResponse.item);
                  navigate('/');
                } else setError('' + userResponse.error);
              });
          }
        }}
      >
        <Row className="g-3">
          <Col sm={12} lg={6}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="firstName">First name</Form.Label>
              <Form.Control
                id="firstName"
                type="text"
                placeholder="First name"
                required={true}
                minLength={2}
              />
            </Form.Group>
          </Col>
          <Col sm={12} lg={6}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="lastName">Last name</Form.Label>
              <Form.Control
                id="lastName"
                type="text"
                placeholder="Last name"
                minLength={2}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email address</Form.Label>
          <Form.Control
            id="email"
            type="email"
            placeholder="name@example.com"
            required={true}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="promoCode">Promo code</Form.Label>
          <Form.Control id="promoCode" type="text" />
        </Form.Group>
        <Row className="g-3 mb-3">
          <Col sm={12} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password" required={true}>
                Password
              </Form.Label>
              <Form.Control
                id="password"
                type="text"
                placeholder="Password"
                required={true}
                minLength={12}
              />
            </Form.Group>
          </Col>
          <Col sm={12} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">
                Confirm Password
              </Form.Label>
              <Form.Control
                id="confirmPassword"
                type="text"
                placeholder="Confirm Password"
                required={true}
                minLength={12}
              />
            </Form.Group>
          </Col>
        </Row>
        {/*<Form.Check type="checkbox" className="mb-3">*/}
        {/*  <Form.Check.Input*/}
        {/*    type="checkbox"*/}
        {/*    name="termsService"*/}
        {/*    id="termsService"*/}
        {/*  />*/}
        {/*  <Form.Check.Label htmlFor="termsService" className="fs-9 text-none">*/}
        {/*    I accept the <Link to="#!">terms </Link>and{' '}*/}
        {/*    <Link to="#!">privacy policy</Link>*/}
        {/*  </Form.Check.Label>*/}
        {/*</Form.Check>*/}
        <Button variant="primary" className="w-100 mb-3" type={'submit'}>
          Sign up
        </Button>
        <div className="text-center">
          <Link to={PATHS.SIGN_IN} className="fs-9 fw-bold">
            Sign in to an existing account
          </Link>
        </div>
      </Form>
    </>
  );
};

export default SignUpForm;
