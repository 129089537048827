import './Shipping.scss';
import React, { useState } from 'react';
import IShipmentOrderItem from '../../../../models/restful/IShipmentOrderItem';
import { api } from '../../../../api/Api';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import IMerchProductOrder from '../../../../models/restful/IMerchProductOrder';
import OrderDetailsSummaryCard from '../../order-view/OrderDetailsSummaryCard';

interface IShippingProps {
  order: IMerchProductOrder;
  shoi: IShipmentOrderItem;
  showRequired: boolean;
  updateOrderFromServer(): void;
}

export const isPhoneValid = (phone: string) => {
  const re = /^[+\d]+$/;
  return !phone || re.test(phone);
};

const Shipping: React.FunctionComponent<IShippingProps> = (
  props: IShippingProps
) => {
  const shoi = props.shoi;

  const [key, setKey] = useState(0);

  if (!shoi) return null;
  const updateOI = (shoi: IShipmentOrderItem, updateOrder = false) => {
    api.update('ShipmentOrderItem', shoi).then(() => {
      setKey(key + 1);
      if (updateOrder) props.updateOrderFromServer();
    });
  };

  return (
    <div className="products-cont">
      <div className="order-item">
        <div className="row">
          <div className="col-8">
            <div className="row">
              <div className="col-4">
                <h4>Shipping</h4>
              </div>
              <div className="col-4">
                <h4>{shoi._props.properties.Price.nice}</h4>
              </div>
            </div>
            <h5>Recipient</h5>
            <div className="row">
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  FirstName
                </label>
                <input
                  type="text"
                  className={
                    'form-control' +
                    (props.showRequired && !shoi.FirstName ? ' is-invalid' : '')
                  }
                  placeholder="John"
                  defaultValue={shoi.FirstName}
                  required={true}
                  onChange={e => {
                    shoi.FirstName = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
                {props.showRequired && !shoi.FirstName && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Please enter your first name
                  </div>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  LastName
                </label>
                <input
                  type="text"
                  className={
                    'form-control' +
                    (props.showRequired && !shoi.LastName ? ' is-invalid' : '')
                  }
                  placeholder="Doe"
                  defaultValue={shoi.LastName}
                  required={true}
                  onChange={e => {
                    shoi.LastName = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
                {props.showRequired && !shoi.LastName && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Please enter your last name
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  Phone
                </label>
                <input
                  type="text"
                  className={
                    'form-control' +
                    (props.showRequired && !isPhoneValid(shoi.Phone)
                      ? ' is-invalid'
                      : '')
                  }
                  placeholder="phone"
                  defaultValue={shoi.Phone}
                  onChange={e => {
                    shoi.Phone = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
                {props.showRequired && !isPhoneValid(shoi.Phone) && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Phone is invalid
                  </div>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  Email
                </label>
                <input
                  type="email"
                  className={
                    'form-control' +
                    (props.showRequired && !shoi.Email ? ' is-invalid' : '')
                  }
                  placeholder="e-mail"
                  defaultValue={shoi.Email}
                  required={true}
                  onChange={e => {
                    shoi.Email = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
                {props.showRequired && !shoi.Email && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Please enter your e-mail
                  </div>
                )}
              </div>
            </div>

            <h5>Shipping address</h5>

            <div className="mb-3 row">
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  Country
                </label>
                <CountryDropdown
                  value={shoi.CountryCode ? shoi.CountryCode : ''}
                  valueType="short"
                  onChange={val => {
                    shoi.CountryCode = val;
                    shoi.RegionName = '';
                    updateOI(shoi, true);
                  }}
                  classes={
                    'form-control' +
                    (props.showRequired && !shoi.CountryCode
                      ? ' is-invalid'
                      : '')
                  }
                />
                {props.showRequired && !shoi.CountryCode && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Please select your country
                  </div>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  Region
                </label>
                <RegionDropdown
                  country={shoi.CountryCode ? shoi.CountryCode : ''}
                  countryValueType="short"
                  value={shoi.RegionName ? shoi.RegionName : ''}
                  onChange={val => {
                    shoi.RegionName = val;
                    updateOI(shoi);
                  }}
                  classes={
                    'form-control' +
                    (props.showRequired &&
                    (!shoi.RegionName || !shoi.CountryCode)
                      ? ' is-invalid'
                      : '')
                  }
                />
                {props.showRequired &&
                  (!shoi.RegionName || !shoi.CountryCode) && (
                    <div className="invalid-feedback mb-4 ps-3">
                      Please select your region
                    </div>
                  )}
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  City
                </label>
                <input
                  type="text"
                  className={
                    'form-control' +
                    (props.showRequired && !shoi.CityName ? ' is-invalid' : '')
                  }
                  placeholder="New York"
                  defaultValue={shoi.CityName}
                  required={true}
                  onChange={e => {
                    shoi.CityName = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
                {props.showRequired && !shoi.CityName && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Please enter your city
                  </div>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  ZIP Code
                </label>
                <input
                  type="text"
                  className={
                    'form-control' +
                    (props.showRequired && !shoi.PostalCode
                      ? ' is-invalid'
                      : '')
                  }
                  placeholder="11111"
                  defaultValue={shoi.PostalCode}
                  required={true}
                  onChange={e => {
                    shoi.PostalCode = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
                {props.showRequired && !shoi.PostalCode && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Please enter your ZIP code
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  Address
                </label>
                <input
                  type="text"
                  className={
                    'form-control' +
                    (props.showRequired && !shoi.Address ? ' is-invalid' : '')
                  }
                  placeholder="Address"
                  defaultValue={shoi.Address}
                  required={true}
                  onChange={e => {
                    shoi.Address = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
                {props.showRequired && !shoi.Address && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Please enter your address
                  </div>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  Address 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  defaultValue={shoi.Address2}
                  onChange={e => {
                    shoi.Address2 = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-4">
            <OrderDetailsSummaryCard
              order={props.order}
              includeOrderItems={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipping;
