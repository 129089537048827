import React from 'react';
import IMerchProductOrder from '../../../models/restful/IMerchProductOrder';

interface IOrderSummaryDetailsProps {
  order: IMerchProductOrder;
}
const OrderSummaryDetails: React.FunctionComponent<
  IOrderSummaryDetailsProps
> = props => {
  const o = props.order;
  return (
    <div>
      <div className="d-flex justify-content-between">
        <p className="text-900 fw-semi-bold">Items subtotal :</p>
        <p className="text-1100 fw-semi-bold">
          {o._props.properties.ItemsAmount.nice}
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="text-900 fw-semi-bold">Subtotal :</p>
        <p className="text-1100 fw-semi-bold">
          {o._props.properties.SubtotalAmount.nice}
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="text-900 fw-semi-bold">Shipping Cost :</p>
        <p className="text-1100 fw-semi-bold">
          {o.ShipmentOrderItem?._props.properties.Price.nice}
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="text-900 fw-semi-bold">Tax :</p>
        <p className="text-1100 fw-semi-bold">{o._props.properties.VAT.nice}</p>
      </div>
    </div>
  );
};

export default OrderSummaryDetails;
