import React from 'react';
import './ProgressBar.scss';

export interface IProgressBarProps {
  valuenow?: number;
  valuemin?: number;
  valuemax?: number;
  completed?: boolean;
  color?: 'primary' | 'success' | 'info' | 'warning' | 'danger';
}

const ProgressBar: React.FunctionComponent<IProgressBarProps> = (
  props: IProgressBarProps
) => {
  const { valuenow = 0, valuemin = 0, valuemax = 100, color } = props;
  const { completed = !(valuenow > valuemin && valuenow < valuemax) } = props;
  const animate = completed
    ? ''
    : ' progress-bar-striped progress-bar-animated';

  return (
    <div className="progress-bar-cont">
      <div className="progress">
        <div
          className={`progress-bar ${animate} bg-${color}`}
          role="progressbar"
          aria-valuenow={valuenow}
          aria-valuemin={valuemin}
          aria-valuemax={valuemax}
          style={{ width: valuenow + '%' }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
