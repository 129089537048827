import PATHS from '../paths';
import { ORDERS_ROUTES } from './orders-routes';
import { PAYMENTS_ROUTES } from './payments-routes';
import { STORES_ROUTES } from './stores-routes';
import TransactionSuccessPage from '../pages/transaction/success/TransactionSuccessPage';
import TransactionCanceledPage from '../pages/transaction/canceled/TransactionCanceledPage';
import Home from '../pages/home/Home';
import { FILES_ROUTES } from './my-files-routes';

export const MAIN_LAYOUT_ROUTES = [
  {
    index: true,
    element: <Home />
  },
  {
    path: PATHS.ORDERS,
    children: ORDERS_ROUTES
  },
  {
    path: PATHS.PAYMENTS,
    children: PAYMENTS_ROUTES
  },
  {
    path: PATHS.STORES,
    children: STORES_ROUTES
  },
  {
    path: PATHS.FILES,
    children: FILES_ROUTES
  },
  {
    path: PATHS.TRANSACTION_SUCCESS,
    element: <TransactionSuccessPage />
  },
  {
    path: PATHS.TRANSACTION_CANCELED,
    element: <TransactionCanceledPage />
  }
];
