import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React from 'react';
import IMerchProductOrder from '../../../models/restful/IMerchProductOrder';
import IMerchProductOrderItem from '../../../models/restful/IMerchProductOrderItem';

interface IOrderDetailsTableProps {
  order: IMerchProductOrder;
}

const columns: ColumnDef<IMerchProductOrderItem>[] = [
  {
    id: 'Mockup',
    accessorKey: '',
    cell: ({ row: { original } }) => {
      let src = '';
      const image = original.Mockup ? original.Mockup : original.Artwork;
      if (image) {
        src = image._props?.thumb128;
      }
      return (
        <div className="rounded-2 border d-inline-block">
          <img src={src} alt="" width={53} />
        </div>
      );
    },
    meta: { cellProps: { className: 'py-2' } }
  },
  {
    accessorKey: 'Title',
    header: 'Product',
    cell: ({ row: { original } }) => original.Title,
    meta: {
      headerProps: { style: { minWidth: 380 } },
      cellProps: { className: '' }
    }
  },
  {
    header: 'Variation',
    cell: ({ row: { original } }) =>
      original.ProductJSON ? JSON.parse(original.ProductJSON).Title : '',
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'white-space-nowrap text-900 ps-4' }
    }
  },
  {
    accessorKey: 'price',
    header: 'Price',
    cell: ({ row: { original } }) => original._props.properties.Price.nice,
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4 text-end' },
      cellProps: { className: 'text-900 fw-semi-bold text-end ps-4' }
    }
  },
  {
    accessorKey: 'Quantity',
    header: 'Quantity',
    meta: {
      headerProps: { style: { width: 200 }, className: 'ps-4 text-end' },
      cellProps: { className: 'text-end ps-4 text-700' }
    }
  }
];

const OrderDetailsTable: React.FunctionComponent<
  IOrderDetailsTableProps
> = props => {
  const o = props.order;
  if (!o._props) return null;
  const table = useAdvanceTable({
    data: o.ProductOrderItems || [],
    columns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });
  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="border-y">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <div className="d-flex flex-between-center py-3">
            <p className="text-1100 fw-semi-bold lh-sm mb-0">
              Items subtotal :
            </p>
            <p className="text-1100 fw-bold lh-sm mb-0">
              {o._props.properties.ItemsAmount.nice}
            </p>
          </div>
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default OrderDetailsTable;
