import React from 'react';
import IMerchProductOrder from '../../../../models/restful/IMerchProductOrder';
import './OrdersActions.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faList,
  faMoneyBill,
  faPencil,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import PATHS from '../../../../paths';
import { pay } from '../OrdersFunctions';
import { api } from '../../../../api/Api';
import { OrdersTablePageSettings } from '../OrdersTablePage';
import { useUser } from '../../hooks/useUser';

interface IOrderActionsProps {
  order: IMerchProductOrder;
  updateFromServer(): void;
}

const OrderActions: React.FunctionComponent<IOrderActionsProps> = props => {
  const o = props.order;
  const navigate = useNavigate();
  const { getUser } = useUser();
  const user = getUser();

  if (!o.Name) return null;
  const duplicateOrder = (order: IMerchProductOrder) => {
    api.duplicateOrder(order.ID).then(resp => {
      const order = resp.item as IMerchProductOrder;
      OrdersTablePageSettings.isUpdateNeeded = true;
      navigate(generatePath(PATHS.ORDER_EDIT, { name: order.Name }));
    });
  };

  const deleteOrders = (ids: number[]) => {
    api.delete('MerchProductOrder', ids).then(() => {
      props.updateFromServer();
    });
  };

  return (
    <>
      <Link
        className="btn btn-outline-secondary btn-sm order-action-btn"
        to={generatePath(PATHS.ORDER_VIEW, { name: o.Name })}
      >
        <FontAwesomeIcon icon={faList} className="ms-1 fs-9" />
        &nbsp; View
      </Link>
      {o.PaymentStatus === 'NONE' && (
        <Link
          className="btn btn-outline-primary btn-sm store-action-btn"
          to={generatePath(PATHS.ORDER_EDIT, { name: o.Name })}
        >
          <FontAwesomeIcon icon={faPencil} className="ms-1 fs-9" />
          &nbsp; Edit
        </Link>
      )}
      <button
        className="btn btn-outline-success btn-sm order-action-btn"
        onClick={() =>
          window.confirm('Really copy order?') ? duplicateOrder(o) : null
        }
      >
        <FontAwesomeIcon icon={faCopy} className="ms-1 fs-9" />
        &nbsp; Copy
      </button>
      {user.IsAdmin && ( //|| o._props.canDelete
        <button
          className="btn btn-outline-danger btn-sm order-action-btn"
          onClick={() =>
            window.confirm('Really delete order?') ? deleteOrders([o.ID]) : null
          }
        >
          <FontAwesomeIcon icon={faTrash} className="ms-1 fs-9" />
          &nbsp; Delete
        </button>
      )}
      {o._props.canPay && (
        <button
          className="btn btn-outline-success btn-sm order-action-btn"
          onClick={() =>
            window.confirm('Really pay order?') ? pay([o]) : null
          }
        >
          <FontAwesomeIcon icon={faMoneyBill} className="ms-1 fs-9" />
          &nbsp; Pay
        </button>
      )}
    </>
  );
};

export default OrderActions;
