import './FileForm.scss';
import React, { useState } from 'react';
import IFile from '../../../models/restful/IFile';
import { api } from '../../../api/Api';
import { generatePath, Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PATHS from '../../../paths';

interface IFileFormProps {
  file?: IFile;
}

const FileForm: React.FunctionComponent<IFileFormProps> = (
  props: IFileFormProps
) => {
  const [file, setFile] = useState<IFile>(props.file || ({} as IFile));
  const { id } = useParams();
  const updateFromServer = () => {
    api.read('File', '' + id).then(resp => {
      if (resp.itemsCollection?.itemsCount) {
        setFile(resp.itemsCollection?.items[0] as IFile);
      }
    });
  };
  if (!file.ID) {
    updateFromServer();
    return null;
  }

  const updateFile = (file: IFile) => {
    api.update('File', file);
  };

  return (
    <div className="file-form-cont">
      <div className="row pt-3 pb-2 mb-3 bfile-bottom">
        <div className="col-6">
          <h3 className="h2 pt-3">File #{file.ID}</h3>
        </div>
        <div className="col-4"></div>
        <div className="col-2">
          <Link
            to="#"
            className="text-900 me-4 px-0"
            onClick={() => updateFromServer()}
          >
            <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
            Reload
          </Link>
          <Link to={generatePath(PATHS.FILES)} className="text-900 me-4 px-0">
            <FontAwesomeIcon icon={faPalette} className="fs-9 me-2" />
            All files
          </Link>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-sm-2 col-form-label">
          Title
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            placeholder="My new file"
            defaultValue={file.Title}
            onChange={e => {
              file.Title = e.currentTarget.value;
              updateFile(file);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FileForm;
