import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilChartPie, UilShoppingCart } from '@iconscout/react-unicons';
import PATHS from './paths';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'home',
        path: '/',
        icon: 'pie-chart',
        active: true,
        hasNew: true,
        flat: true
      }
    ]
  },
  {
    label: 'e-commerce',
    icon: UilShoppingCart,
    pages: [
      {
        name: 'orders',
        active: true,
        icon: 'shopping-cart',
        flat: true,
        path: PATHS.ORDERS
      },
      {
        name: 'payments',
        active: true,
        icon: 'dollar-sign',
        flat: true,
        path: PATHS.PAYMENTS
      },
      {
        name: 'stores',
        active: true,
        icon: 'package',
        flat: true,
        path: PATHS.STORES
      },
      {
        name: 'my-files',
        active: true,
        icon: 'package',
        flat: true,
        path: PATHS.FILES
      }
    ]
  }
];
