import './SubscriptionItemBlock.scss';
import React, { CSSProperties } from 'react';
import { Col } from 'react-bootstrap';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Badge from 'components/base/Badge';
import { faCheck, faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import ISubscriptionPlan from '../../../../models/restful/ISubscriptionPlan';
import ISubscriptionFeature from '../../../../models/restful/ISubscriptionFeature';
import { api } from '../../../../api/Api';

interface PricingColumnItemProps {
  plan: ISubscriptionPlan;
  features: ISubscriptionFeature[];
  selected: boolean;
}

const SubscriptionItemBlock = (props: PricingColumnItemProps) => {
  const plan = props.plan;

  return (
    <Col sm={6} xxl={3} key={plan.ID}>
      <div className="card subscription-item-block">
        <div className="img-cont">
          <img
            src={plan._props.previewImageUrl}
            alt=""
            className="card-img-top mb-4"
            style={{ height: '120px', width: 'auto', overflow: 'hidden' }}
          />
        </div>
        <div className="card-body">
          <div className="pricing-column-title-box mb-sm-5">
            <h3 className="mb-2">{plan.Title}</h3>
            <p className="text-800 mb-0 pe-3">{plan.Description}</p>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-12 mt-2">
              <div className="d-flex align-items-center mb-4">
                {plan.PriceForMonth < 0.01 && !plan.EmailForPrice && (
                  <h4 className="display-4 fw-bolder">Free</h4>
                )}
                {plan.PriceForMonth < 0.01 && plan.EmailForPrice && (
                  <h4 className="display-4 fw-bolder">Contact</h4>
                )}
                {plan.PriceForMonth > 0.01 && (
                  <>
                    <h4 className="display-4 fw-bolder">
                      {plan._props.properties.PriceForMonth.nice}
                    </h4>
                    <h5 className="fs-0 fw-normal ms-1">/ month</h5>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <Button
                variant={props.selected ? 'success' : 'primary'}
                size="lg"
                className="w-100 mb-6"
                onClick={() => {
                  if (plan.EmailForPrice)
                    window.open(`mailto:${plan.EmailForPrice}`);
                  else
                    api
                      .payPlan(plan, { period: 'month' })
                      .then(resp => (window.location.href = resp.item.url));
                }}
              >
                {!plan.EmailForPrice && 'Buy'}
                {plan.EmailForPrice && 'send'}
              </Button>
            </div>
          </div>
          <div className="row" style={{ opacity: plan.EmailForPrice ? 0 : 1 }}>
            <div className="col-lg-8 col-md-12 mt-1">
              <div className="d-flex align-items-center mb-2">
                {plan.PriceForYear < 0.01 && !plan.EmailForPrice && (
                  <h4 className="display-6 fw-bolder">Free</h4>
                )}
                {plan.PriceForYear < 0.01 && plan.EmailForPrice && (
                  <h4 className="display-6 fw-bolder">Contact</h4>
                )}
                {plan.PriceForYear > 0.01 && (
                  <>
                    <h4 className="display-6 fw-bolder">
                      {plan._props.properties.PriceForYear.nice}
                    </h4>
                    <h5 className="fs-0 fw-normal ms-1">/ year</h5>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <Button
                variant={props.selected ? 'primary' : 'outline-primary'}
                size="sm"
                className="w-100"
                onClick={() => {
                  api
                    .payPlan(plan, { period: 'year' })
                    .then(resp => (window.location.href = resp.item.url));
                }}
              >
                Buy
              </Button>
            </div>
          </div>

          <h5 className="mt-3 mb-4">What’s included</h5>
          <ul
            className="fa-ul"
            style={{ '--fa-li-margin': '1.5em' } as CSSProperties}
          >
            {props.features.map((feature, i) => {
              const featureIncludes = !!plan.Features.find(
                f => f.ID === feature.ID
              );
              return (
                <li
                  key={i}
                  className={classNames('mb-2 text-500', {
                    'text-900': featureIncludes
                  })}
                >
                  <span className="fa-li me-2 stack-icon-item">
                    {feature.IsPersistent && (
                      <span className="fa-layers fa-fw">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-primary"
                        />
                      </span>
                    )}
                    {!feature.IsPersistent && (
                      <span className="fa-layers fa-fw">
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={classNames('text-300', {
                            'text-success': featureIncludes
                          })}
                        />
                        <FontAwesomeIcon
                          icon={featureIncludes ? faCheck : faTimes}
                          inverse
                          className={classNames('text-600 fs-11', {
                            'text-white': featureIncludes
                          })}
                        />
                      </span>
                    )}
                  </span>
                  {feature.Title}
                  {feature.IsNew && (
                    <Badge
                      variant="phoenix"
                      bg="primary"
                      className="ms-2 fs-10"
                    >
                      New
                    </Badge>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Col>
  );
};

export default SubscriptionItemBlock;
