import './StoreForm.scss';
import React, { useState } from 'react';
import IMerchStore from '../../../models/restful/IMerchStore';
import { api } from '../../../api/Api';
import { generatePath, Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PATHS from '../../../paths';

interface IStoreFormProps {
  store?: IMerchStore;
}

const StoreForm: React.FunctionComponent<IStoreFormProps> = (
  props: IStoreFormProps
) => {
  const [store, setStore] = useState<IMerchStore>(
    props.store || ({} as IMerchStore)
  );
  const { id } = useParams();
  const updateFromServer = () => {
    api.read('MerchStore', '' + id).then(resp => {
      if (resp.itemsCollection?.itemsCount) {
        setStore(resp.itemsCollection?.items[0] as IMerchStore);
      }
    });
  };
  if (!store.ID) {
    updateFromServer();
    return null;
  }

  const updateStore = (store: IMerchStore) => {
    api.update('MerchStore', store);
  };

  return (
    <div className="store-form-cont">
      <div className="row pt-3 pb-2 mb-3 bstore-bottom">
        <div className="col-6">
          <h3 className="h2 pt-3">Store #{store.ID}</h3>
        </div>
        <div className="col-4"></div>
        <div className="col-2">
          <Link
            to="#"
            className="text-900 me-4 px-0"
            onClick={() => updateFromServer()}
          >
            <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
            Reload
          </Link>
          <Link to={generatePath(PATHS.STORES)} className="text-900 me-4 px-0">
            <FontAwesomeIcon icon={faPalette} className="fs-9 me-2" />
            All stores
          </Link>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-sm-2 col-form-label">
          Title
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            placeholder="My new store"
            defaultValue={store.Title}
            onChange={e => {
              store.Title = e.currentTarget.value;
              updateStore(store);
            }}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-sm-2 col-form-label">
          Description
        </label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            rows={7}
            placeholder="Description"
            defaultValue={store.Description}
            onChange={e => {
              store.Description = e.currentTarget.value;
              updateStore(store);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreForm;
