import './FileView.scss';
import React, { useState } from 'react';
import IFile from '../../../models/restful/IFile';
import FilePreview from './FilePreview/FilePreview';
import { generatePath, Link, useParams } from 'react-router-dom';
import { api } from '../../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PATHS from '../../../paths';

interface IFileViewProps {
  file?: IFile;
}

const FileView: React.FunctionComponent<IFileViewProps> = (
  props: IFileViewProps
) => {
  const [file, setFile] = useState<IFile>(props.file || ({} as IFile));
  const { id } = useParams();
  const updateFromServer = () => {
    api.read('File', '' + id).then(resp => {
      if (resp.itemsCollection?.itemsCount) {
        setFile(resp.itemsCollection?.items[0] as IFile);
      }
    });
  };
  if (!file.ID) {
    updateFromServer();
    return null;
  }

  return (
    <div className="file-view-cont">
      <div className="row pt-3 pb-2 mb-3 bfile-bottom">
        <div className="col-6">
          <h3 className="h2 pt-3">File #{file.ID}</h3>
        </div>
        <div className="col-4"></div>
        <div className="col-2">
          <Link
            to="#"
            className="text-900 me-4 px-0"
            onClick={() => updateFromServer()}
          >
            <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
            Reload
          </Link>
          <Link to={generatePath(PATHS.FILES)} className="text-900 me-4 px-0">
            <FontAwesomeIcon icon={faPalette} className="fs-9 me-2" />
            All files
          </Link>
        </div>
      </div>
      <FilePreview file={file} />
    </div>
  );
};

export default FileView;
