import './Home.scss';
import React from 'react';
import { api } from '../../api/Api';
import SubscriptionPlansBlock from './SubscriptionPlansBlock/SubscriptionPlansBlock';

const Home = () => (
  <div>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 className="h2">Dashboard</h1>
      <div className="btn-toolbar mb-2 mb-md-0">
        <div className="btn-group me-2">
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary"
            onClick={() => {
              const url =
                api.host + '/app-api/download-price?token=' + api.getToken();
              window.open(url, '_blank')?.focus();
            }}
          >
            <i className="fa-solid fa-file-export" />
            Download price
          </button>
        </div>
      </div>
    </div>
    <SubscriptionPlansBlock />
  </div>
);

export default Home;
