import OrderDetailsTable from './OrderDetailsTable';
import { Card, Col, Row } from 'react-bootstrap';
import OrderDetailsSummaryCard from './OrderDetailsSummaryCard';
import { Link, useParams } from 'react-router-dom';
import IMerchProductOrder from '../../../models/restful/IMerchProductOrder';
import React, { useState } from 'react';
import { api } from '../../../api/Api';
import OrderInfoItem from '../../../components/info-items/OrderInfoItem';

interface IOrderDetailsProps {
  order?: IMerchProductOrder;
  hideTitle?: boolean;
}

const OrderDetails: React.FunctionComponent<IOrderDetailsProps> = props => {
  const [o, setO] = useState<IMerchProductOrder>(
    props.order || ({} as IMerchProductOrder)
  );
  const { name } = useParams();
  if (!o.ID) {
    api.read('MerchProductOrder', '' + name, 'Name').then(resp => {
      if (resp.itemsCollection?.itemsCount) {
        setO(resp.itemsCollection?.items[0] as IMerchProductOrder);
      }
    });
    return null;
  }

  return (
    <div>
      <div className="mb-9">
        {!props.hideTitle && (
          <>
            <h2 className="mb-1">
              Order <span>{o?.Name}</span>
            </h2>
            <hr />
          </>
        )}

        <Row>
          <Col xs={12} xl={8} xxl={9}>
            <h4 className="mb-5">Shipping details</h4>
            <Row className="gx-4 gy-6 g-xl-7 justify-content-sm-center justify-content-xl-start">
              <Col xs={12} sm="auto">
                <Row className="g-4 flex-sm-column">
                  <Col md={4} xs={6} sm={12}>
                    <OrderInfoItem icon="mail" label="Email" />
                    <Link
                      className="fs-9 ms-4"
                      to={'mailto:' + o.ShipmentOrderItem?.Email}
                    >
                      {o.ShipmentOrderItem?.Email}
                    </Link>
                  </Col>
                  <Col md={4} xs={6} sm={12}>
                    <OrderInfoItem icon="phone" label="Phone" />
                    <Link
                      className="fs-9 ms-4"
                      to={'tel:' + o.ShipmentOrderItem?.Phone}
                    >
                      {o.ShipmentOrderItem?.Phone}
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm="auto">
                <Row className="g-4 flex-sm-column">
                  <Col md={4} xs={6} sm={12}>
                    <OrderInfoItem icon="home" label="Address" />
                    <div className="ms-4">
                      <p className="text-800 mb-0 fs-9">
                        {o.ShipmentOrderItem?.FirstName}{' '}
                        {o.ShipmentOrderItem?.LastName}
                      </p>
                      <p className="text-800 mb-0 fs-9">
                        {o.ShipmentOrderItem?.Address}
                      </p>
                      <p className="text-800 mb-0 fs-9">
                        {o.ShipmentOrderItem?.RegionName}
                      </p>
                      <p className="text-800 mb-0 fs-9">
                        {o.ShipmentOrderItem?.CountryName}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4} xs={6} sm={12}>
                <OrderInfoItem icon="delivery" label="Tracking ID" />
                <p>{o.ShipmentOrderItem?.Parcels[0]?.TrackingID}</p>
              </Col>
            </Row>
            <Row className="g-5 gy-7">
              <Col xs={12} xl={8} xxl={9}>
                <div className="mb-6">
                  <OrderDetailsTable order={o} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={4} xxl={3}>
            <OrderDetailsSummaryCard order={o} />
            <Card>
              <Card.Body>
                <Card.Title as="h3" className="mb-4">
                  Order Status
                </Card.Title>
                <h6 className="mb-2">Payment status</h6>
                <p>{o.PaymentStatus}</p>
                <h6 className="mb-2">Order status</h6>
                <p>{o.OrderStatus?.Title}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <hr />
      </div>
    </div>
  );
};

export default OrderDetails;
