import './Design.scss';
import React, { useState } from 'react';
import { api } from '../../../../../api/Api';
import IMerchProductOrderItem from '../../../../../models/restful/IMerchProductOrderItem';
import DropZoneField from '../../../../../components/common/DropZoneField/DropZoneField';
import ProgressBar from '../../../../../components/common/ProgressBar/ProgressBar';
import IMerchProduct from '../../../../../models/restful/IMerchProduct';
import { Form } from 'react-bootstrap';

interface IOrderProps {
  orderItem: IMerchProductOrderItem;
  products: IMerchProduct[];
  showRequired: boolean;
  updateOrderFromServer(): void;
}

interface ISelectOption {
  label: string;
  value: string;
}

const Design: React.FunctionComponent<IOrderProps> = (props: IOrderProps) => {
  const oi = props.orderItem;
  const product = props.products.find(p => p.ID === oi.ProductID);
  const [mockupUploadProgress, setMockupUploadProgress] = useState<number>(0);
  const [mockupUploading, setMockupUploading] = useState<boolean>(false);
  const [artworkUploadProgress, setArtworkUploadProgress] = useState<number>(0);
  const [artworkUploading, setArtworkUploading] = useState<boolean>(false);
  const [key, setKey] = useState(0);

  const updateOI = (newOi: IMerchProductOrderItem, updateState = false) => {
    api.update('MerchProductOrderItem', newOi).then(() => {
      if (updateState) props.updateOrderFromServer();
      else setKey(key + 1);
    });
  };

  const uploadFile = (
    newOi: IMerchProductOrderItem,
    name: string,
    file: File
  ) => {
    if (name === 'Mockup') setMockupUploading(true);
    if (name === 'Artwork') setArtworkUploading(true);
    api
      .upload('MerchProductOrderItem', newOi.ID, name, [file], {
        onUploadProgress: progressEvent => {
          if (name === 'Mockup')
            setMockupUploadProgress(progressEvent.progress as number);
          if (name === 'Artwork')
            setArtworkUploadProgress(progressEvent.progress as number);
        }
      })
      .then(resp => {
        if (name === 'Mockup') {
          newOi.Mockup = resp.itemsCollection.items[0];
          newOi.MockupID = newOi.Mockup.ID;
        }
        if (name === 'Artwork') {
          newOi.Artwork = resp.itemsCollection.items[0];
          newOi.ArtworkID = newOi.Artwork.ID;
        }
        if (name === 'Mockup') setMockupUploading(false);
        if (name === 'Artwork') setArtworkUploading(false);
      });
  };

  const duplicateOrderItem = () => {
    api
      .createOrderItem({
        ProductID: oi.ProductID,
        OrderID: oi.OrderID
      } as IMerchProductOrderItem)
      .then(() => props.updateOrderFromServer());
  };

  const deleteOrderItem = () => {
    api.deleteOrderItem(oi).then(() => props.updateOrderFromServer());
  };

  const dds: ISelectOption[] = [];
  product?.Colors.map(c =>
    product?.Sizes.map(s =>
      product?.Models.map(m =>
        dds.push({
          label: `${c.Title}, ${s.Title}, ${m.Title}`,
          value: JSON.stringify({
            ColorID: c.ID,
            SizeID: s.ID,
            ModelID: m.ID,
            Title: `${c.Title}, ${s.Title}, ${m.Title}`
          })
        })
      )
    )
  );
  return (
    <div className="order-item needs-validation" key={key}>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Product</label>
        <div className="col-sm-6">
          {product?.Title}
          {/*<input type="text" className="form-control"*/}
          {/*       placeholder="My new order" defaultValue={oi.Title ? oi.Title : ''}*/}
          {/*       onChange={e => {*/}
          {/*           oi.Title = e.currentTarget.value;*/}
          {/*           updateOI(oi);*/}
          {/*       }}*/}
          {/*/>*/}
        </div>
        <div className="col-sm-2">
          <h4>{oi._props.properties.Price.nice}</h4>
        </div>
        <div className="col-sm-2 text-end">
          <button
            className="btn btn-outline-success btn-sm order-item-action-btn"
            onClick={() =>
              window.confirm('Really copy design?')
                ? duplicateOrderItem()
                : null
            }
          >
            <i className="fa-solid fa-copy" />
            Copy
          </button>
          <button
            className="btn btn-outline-danger btn-sm order-item-action-btn"
            onClick={() =>
              window.confirm('Really delete design?') ? deleteOrderItem() : null
            }
          >
            <i className="fa-solid fa-trash" />
            Delete
          </button>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Quantity</label>
        <div className="col-sm-10">
          <input
            type="number"
            className="form-control"
            placeholder="My new order"
            defaultValue={oi.Quantity}
            onChange={e => {
              oi.Quantity = parseInt(e.currentTarget.value);
              updateOI(oi, true);
            }}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <div className="col-sm-2">Product</div>
        <div className="col-sm-2">
          <div className="card" style={{ width: '10rem' }}>
            <img
              className="card-img-top"
              src={product?._props.previewImageUrl}
              alt={product?.Title}
            />
            <div className="card-body">
              <h5 className="card-title">{product?.Title}</h5>
              <p className="card-text">{product?.Description}</p>
            </div>
          </div>
        </div>
        <div className="col-sm-8">
          <p>Choose a variation</p>
          <Form.Select
            className={
              'form-select form-select-sm mb-3' +
              (props.showRequired && !oi.ProductJSON ? ' is-invalid' : '')
            }
            defaultValue={oi.ProductJSON}
            onChange={e => {
              oi.ProductJSON = e.target.value;
              updateOI(oi, true);
            }}
            required={true}
          >
            <option value="">Choose a variation</option>
            {dds.map((o, idx) => (
              <option value={o.value} key={idx}>
                {o.label}
              </option>
            ))}
          </Form.Select>
          {props.showRequired && !oi.ProductJSON && (
            <div className="invalid-feedback mb-4 ps-3">
              Please select variation
            </div>
          )}
          <div className="row">
            <div className="col-sm-6">
              <div>
                {oi.Mockup && oi.Mockup._props && (
                  <div>
                    <p>{oi.Mockup && oi.Mockup.Title}</p>
                    <div style={{ height: '120px' }}>
                      <img
                        src={oi.Mockup?._props?.thumb128}
                        alt={oi.Mockup?.Title}
                        style={{ height: '120px' }}
                      />
                    </div>

                    <hr />
                  </div>
                )}
                {mockupUploading && (
                  <ProgressBar valuenow={mockupUploadProgress * 100} />
                )}
                {!mockupUploading && (
                  <DropZoneField
                    accept="image/png, image/jpeg"
                    showButton={true}
                    buttonText="Choose Mockup"
                    height="200px"
                    dropzoneText="Drop Mockup here"
                    onChange={e => {
                      uploadFile(oi, 'Mockup', e.target.files?.item(0) as File);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className={
                  props.showRequired && !oi.Artwork
                    ? 'px-3 py-3 border border-danger'
                    : ''
                }
              >
                {oi.Artwork && oi.Artwork._props && (
                  <div>
                    <p>{oi.Artwork && oi.Artwork.Title}</p>
                    <div style={{ height: '120px' }}>
                      <img
                        src={oi.Artwork._props.thumb128}
                        alt={oi.Artwork.Title}
                        style={{ height: '120px' }}
                      />
                    </div>
                    <hr />
                  </div>
                )}
                {artworkUploading && (
                  <ProgressBar valuenow={artworkUploadProgress * 100} />
                )}
                {!artworkUploading && (
                  <DropZoneField
                    accept="image/png, image/jpeg"
                    showButton={true}
                    buttonText="Choose Artwork"
                    height="200px"
                    dropzoneText="Drop Artwork here"
                    onChange={e => {
                      uploadFile(
                        oi,
                        'Artwork',
                        e.target.files?.item(0) as File
                      );
                    }}
                  />
                )}
              </div>
              {props.showRequired && !oi.Mockup && (
                <div
                  className="invalid-feedback mb-4 ps-3 pt-3"
                  style={{ display: 'block' }}
                >
                  Please select artwork
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Design;
