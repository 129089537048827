import './TransactionSuccessPage.scss';
import React, { useEffect } from 'react';
import { api } from '../../../api/Api';
import { generatePath, Link, useParams } from 'react-router-dom';
import { OrdersTablePageSettings } from '../../orders/orders-table/OrdersTablePage';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PATHS from '../../../paths';
import { PaymentsTableSettings } from '../../payments/Table/Table';

interface ITransactionSuccessPageProps {}

const TransactionSuccessPage: React.FunctionComponent<
  ITransactionSuccessPageProps
> = () => {
  const { hash } = useParams();

  useEffect(() => {
    api.transaction({ hash: `${hash}`, success: true }).then(() => {
      OrdersTablePageSettings.isUpdateNeeded = true;
      PaymentsTableSettings.isUpdateNeeded = true;
    });
  }, []);

  return (
    <div className="transaction-success-cont">
      <p className="text-center mb-3" style={{ fontSize: '48px' }}>
        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
      </p>
      <h1 className="text-center text-success mb-3">Payment successful</h1>
      <p className="text-center mb-3">
        <span
          className="bg-success-100"
          style={{
            width: '180px',
            height: '180px',
            borderRadius: '50%',
            display: 'inline-block'
          }}
        >
          <span
            className="bg-success-200"
            style={{
              width: '150px',
              height: '150px',
              borderRadius: '50%',
              display: 'inline-block',
              marginTop: '15px'
            }}
          >
            <span
              className="bg-success-300"
              style={{
                width: '120px',
                height: '120px',
                borderRadius: '50%',
                display: 'inline-block',
                marginTop: '15px'
              }}
            >
              <span
                className="text-success"
                style={{
                  fontSize: '86px',
                  borderRadius: '50%',
                  width: '90px',
                  height: '90px',
                  display: 'inline-block'
                }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="bg-white"
                  style={{
                    borderRadius: '50%'
                  }}
                />
              </span>
            </span>
          </span>
        </span>
      </p>
      <div className="row pt-3">
        <div className="col-4"></div>
        <div className="col-4">
          <div className="d-grid gap-2">
            <Link
              to={generatePath(PATHS.ORDERS)}
              className="mb-3 btn btn-primary"
            >
              Show my orders
            </Link>
            <Link
              to={generatePath(PATHS.PAYMENTS)}
              className="mb-3 btn btn-primary"
            >
              Show my payments
            </Link>
          </div>
        </div>
        <div className="col-4"></div>
      </div>
    </div>
  );
};

export default TransactionSuccessPage;
