import './StoresPage.scss';
import React, { useEffect, useState } from 'react';
import IMerchStore from '../../models/restful/IMerchStore';
import { api } from '../../api/Api';
import Loader from '../../components/common/Loader/Loader';
import Button from '../../components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport,
  faPlus,
  faRefresh,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import PATHS from '../../paths';
import { IStoreCollection } from '../../models/collections/IStoreCollection';
import ICollectionParams from '../../models/collections/ICollectionParams';
import StoresActions from './stores-actions/StoresActions';

interface IStoresProps {}

export const StoresPageSettings = {
  isUpdateNeeded: false
};

const StoresPage: React.FunctionComponent<IStoresProps> = () => {
  const storeDefaultCollection = useLoaderData() as IStoreCollection;
  const [loading, setLoading] = useState<boolean>(false);

  const [storesCollection, setStoresCollection] = useState<IStoreCollection>(
    storeDefaultCollection
  );

  const [selectedStores, setSelectedStores] = useState<IMerchStore[]>([]);

  const navigate = useNavigate();
  const updateFromServer = (storesCollectionParams?: IStoreCollection) => {
    storesCollectionParams = storesCollectionParams
      ? storesCollectionParams
      : storesCollection;
    setLoading(true);
    api.getCollection('MerchStore', storesCollectionParams).then(resp => {
      setStoresCollection(resp.itemsCollection as IStoreCollection);
      setLoading(false);
    });
  };

  useEffect(() => {
    updateFromServer();
  }, []);

  const deleteStores = (stores: IMerchStore[]) => {
    const collectionParams: ICollectionParams = {
      pageNo: storesCollection.pageNo,
      perPage: storesCollection.perPage
    };
    api
      .delete(
        'MerchStore',
        stores.map(s => s.ID),
        collectionParams
      )
      .then(resp => {
        setStoresCollection(resp.itemsCollection as IStoreCollection);
      });
  };

  return (
    <div className="stores-cont">
      {!loading && (
        <div>
          <div className="row">
            <div className="col-6">
              <h2 className="mb-4">Stores</h2>
            </div>
            <div className="col-6">
              <div className="ms-xxl-auto text-end">
                <Button
                  variant="link"
                  className="text-900 me-4 px-0"
                  onClick={() => updateFromServer(storesCollection)}
                >
                  <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
                  Reload
                </Button>
                <Button
                  variant="link"
                  className="text-900 me-4 px-0 text-danger"
                  onClick={() =>
                    confirm('Really delete selected stores?')
                      ? deleteStores(selectedStores)
                      : null
                  }
                >
                  <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
                  Delete
                </Button>
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    api
                      .create('MerchStore', {} as IMerchStore)
                      .then(resp =>
                        navigate(
                          generatePath(PATHS.ORDER_EDIT, { id: resp.item?.ID })
                        )
                      );
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add store
                </Button>
              </div>
            </div>
          </div>
          <div className="table-responsive stores-table">
            {storesCollection.pageNo < 0 && <Loader />}
            {storesCollection.pageNo > 0 && (
              <div>
                <table className="phoenix-table fs-9 table">
                  <thead>
                    <tr>
                      <th scope="col" className="align-middle">
                        <input
                          type="checkbox"
                          onChange={e => {
                            const checked = e.currentTarget.checked;
                            setSelectedStores(
                              checked ? storesCollection.items : []
                            );
                          }}
                        />
                      </th>
                      <th scope="col">Created</th>
                      <th scope="col">Title</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storesCollection.items.map((store, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="align-middle">
                            <input
                              type="checkbox"
                              data-id={store.ID}
                              checked={
                                !!selectedStores.find(or => or.ID === store.ID)
                              }
                              onChange={e => {
                                const checked = e.currentTarget.checked;
                                if (checked)
                                  setSelectedStores([store, ...selectedStores]);
                                else {
                                  const ors = [...selectedStores].filter(
                                    ord => ord.ID !== store.ID
                                  );
                                  setSelectedStores(ors);
                                }
                              }}
                            />
                          </td>
                          <td>{store.Created}</td>
                          <td>{store.Title}</td>
                          <td>
                            <StoresActions
                              store={store}
                              updateFromServer={updateFromServer}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default StoresPage;
