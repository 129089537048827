import './Table.scss';
import React, { useEffect, useState } from 'react';
import IMerchProductOrder from '../../../../models/restful/IMerchProductOrder';
import { IOrderCollection } from '../../../../models/collections/IOrderCollection';
import { IProductOrderCollection } from '../../../../models/collections/IProductOrderCollection';
import ICollectionParams from '../../../../models/collections/ICollectionParams';
import { api } from '../../../../api/Api';
import Loader from '../../../../components/common/Loader/Loader';
import { pay } from '../OrdersFunctions';
import Pagination from '../../../../components/common/Pagination/Pagination';
import PaymentStatusBlock from '../payment-status-block/PaymentStatusBlock';
import OrderActions from '../order-actions/OrderActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import { useUser } from '../../hooks/useUser';

interface ITableProps {}

const perPageValues = [10, 20, 50, 100];
const defaultOrderCollection: IOrderCollection = {
  perPage: perPageValues[0],
  pageNo: -1,
  sortColumn: 'Created',
  sortDirection: '-',
  totalCount: 0,
  itemsCount: 0,
  items: []
};

const Table: React.FunctionComponent<ITableProps> = () => {
  const [ordersCollection, setOrdersCollection] =
    useState<IProductOrderCollection>(defaultOrderCollection);
  const { getUser } = useUser();
  const user = getUser();

  const [selectedOrders, setSelectedOrders] = useState<IMerchProductOrder[]>(
    []
  );

  const updateFromServer = (collectionParams?: ICollectionParams) => {
    collectionParams = collectionParams ? collectionParams : ordersCollection;
    api.getCollection('MerchProductOrder', collectionParams).then(resp => {
      setOrdersCollection(resp.itemsCollection as IProductOrderCollection);
    });
  };

  const deleteOrders = (orders: IMerchProductOrder[]) => {
    const collectionParams: ICollectionParams = {
      pageNo: ordersCollection.pageNo,
      perPage: ordersCollection.perPage
    };
    api
      .delete(
        'MerchProductOrder',
        orders.map(o => o.ID),
        collectionParams
      )
      .then(resp => {
        setOrdersCollection(resp.itemsCollection as IProductOrderCollection);
      });
  };

  const pageChange = (pageNo: number) => {
    updateFromServer({ ...ordersCollection, pageNo: pageNo });
  };

  useEffect(() => {
    updateFromServer();
  }, []);

  return (
    <div className="table-responsive orders-table">
      {ordersCollection.pageNo < 0 && <Loader />}
      {ordersCollection.pageNo > 0 && (
        <div>
          <div className="row orders-header">
            <div className="col-md-6">
              <Pagination
                {...ordersCollection}
                pageChange={pageChange}
                paginationCount={5}
              />
            </div>
            <div className="col-md-3 text-center">
              <span className="btn btn-sm">
                {ordersCollection.totalCount} orders
              </span>
            </div>
            <div className="col-md-2 text-end"></div>
            <div className="col-md-1 text-end">
              <Form.Select
                className="mb-4"
                onChange={e =>
                  updateFromServer({
                    ...ordersCollection,
                    perPage: parseInt(e.target.value)
                  })
                }
              >
                {perPageValues.map(v => (
                  <option value={v} key={v}>
                    per page {v}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <hr />
          <table className="phoenix-table fs-9 table">
            <thead>
              <tr>
                <th scope="col" className="align-middle">
                  <input
                    type="checkbox"
                    onChange={e => {
                      const checked = e.currentTarget.checked;
                      setSelectedOrders(checked ? ordersCollection.items : []);
                    }}
                  />
                </th>
                <th scope="col" className="tsort">
                  Name
                </th>
                <th scope="col" className="text-start sort">
                  External ID
                </th>
                <th scope="col" className="text-start sort">
                  Date
                </th>
                <th scope="col" className="text-end sort">
                  Total
                </th>
                <th scope="col" className="text-center">
                  Image
                </th>
                <th scope="col" className="text-center sort">
                  Payment status
                </th>
                <th scope="col" className="text-center sort">
                  Order status
                </th>
                <th scope="col" className="text-start sort">
                  Title
                </th>
                <th scope="col" className="text-start">
                  Tracking ID
                </th>
                <th scope="col" className="text-start">
                  Store
                </th>
                <th scope="col">
                  <div className="row">
                    <div className="col-2">
                      <span className="btn btn-sm order-action-btn">
                        Actions
                      </span>
                    </div>
                    <div className="col-10 text-end">
                      {user.IsAdmin && (
                        <button
                          className="btn btn-outline-danger btn-sm order-action-btn"
                          onClick={() =>
                            window.confirm('Really delete order?')
                              ? deleteOrders(selectedOrders)
                              : null
                          }
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="ms-1 fs-9"
                          />
                          &nbsp; Delete
                        </button>
                      )}
                      <button
                        className="btn btn-outline-success btn-sm order-action-btn"
                        onClick={() =>
                          window.confirm('Really pay order?')
                            ? pay(selectedOrders)
                            : null
                        }
                      >
                        <FontAwesomeIcon
                          icon={faMoneyBill}
                          className="ms-1 fs-9"
                        />
                        &nbsp; Pay
                      </button>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {ordersCollection.items.map((o, idx) => {
                const ois = o.ProductOrderItems;
                let src = '';
                if (ois[0]) {
                  const image = ois[0]?.Mockup
                    ? ois[0]?.Mockup
                    : ois[0]?.Artwork;
                  src = image?._props.thumb128;
                }

                const prod = ois.length ? ois[0].Product?.Title : '';
                return (
                  <tr key={idx}>
                    <td className="align-middle">
                      <input
                        type="checkbox"
                        data-id={o.ID}
                        checked={!!selectedOrders.find(or => or.ID === o.ID)}
                        onChange={e => {
                          const checked = e.currentTarget.checked;
                          if (checked)
                            setSelectedOrders([o, ...selectedOrders]);
                          else {
                            const ors = [...selectedOrders].filter(
                              ord => ord.ID !== o.ID
                            );
                            setSelectedOrders(ors);
                          }
                        }}
                      />
                    </td>
                    <td className="text-start">{o.Name}</td>
                    <td className="text-start">
                      <span
                        title={o.ExternalID}
                        style={{
                          display: 'inline-block',
                          width: '160px',
                          overflow: 'hidden'
                        }}
                      >
                        {o.ExternalID}
                      </span>
                    </td>
                    <td className="text-start">{o.Created}</td>
                    <td className="text-end">
                      {o._props.properties.Price.nice}
                    </td>
                    <td className="text-center">
                      {src && (
                        <p className="mb-2">
                          <span
                            style={{
                              display: 'inline-block',
                              width: '60px',
                              height: '80px',
                              backgroundImage: `url("${src}")`,
                              backgroundPosition: 'center center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat'
                            }}
                          ></span>
                        </p>
                      )}
                      <p className="mb-0">{prod}</p>
                    </td>
                    <td className="text-center">
                      <PaymentStatusBlock order={o} />
                    </td>
                    <td className="text-center">{o.OrderStatus?.Title}</td>
                    <td className="text-start">{o.Title}</td>
                    <td className="text-start">
                      {o.ShipmentOrderItem?.Parcels[0]?.TrackingID}
                    </td>
                    <td className="text-start">{o.Store?.Title}</td>
                    <td className="text-start">
                      <OrderActions
                        order={o}
                        updateFromServer={updateFromServer}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Table;
