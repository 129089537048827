import React from 'react';
import './PaymentStatusBlock.scss';
import FeatherIcon from 'feather-icons-react';
import Badge from '../../../components/base/Badge';
import IPayment from '../../../models/restful/IPayment';

interface IPaymentStatusBlockProps {
  payment: IPayment;
}

const PaymentStatusBlock: React.FunctionComponent<
  IPaymentStatusBlockProps
> = props => {
  const p = props.payment;
  const s = p.Status;
  const bg = s === 'PAID' ? 'success' : s === 'WAITING' ? 'info' : 'secondary';
  const icon = s === 'PAID' ? 'check' : s === 'WAITING' ? 'clock' : 'info';

  return (
    <Badge
      bg={bg}
      variant="phoenix"
      iconPosition="end"
      className="fs-10"
      icon={<FeatherIcon icon={icon} size={12.8} className="ms-1" />}
    >
      {p.Status}
    </Badge>
  );
};

export default PaymentStatusBlock;
