import PATHS from '../paths';
import StoresPage from '../pages/stores/StoresPage';
import StoresLoader from '../loaders/StoresLoader/StoresLoader';
import StoreView from '../pages/stores/StoreView/StoreView';
import StoreForm from '../pages/stores/StoreForm/StoreForm';

export const STORES_ROUTES = [
  {
    index: true,
    element: <StoresPage />,
    loader: () => StoresLoader()
  },
  {
    path: PATHS.STORE_VIEW,
    element: <StoreView />
  },
  {
    path: PATHS.STORE_EDIT,
    element: <StoreForm />
  }
];
