import React from 'react';
import IMerchProductOrder from '../../../../models/restful/IMerchProductOrder';
import './PaymentStatusBlock.scss';
import FeatherIcon from 'feather-icons-react';
import Badge from '../../../../components/base/Badge';

interface IPaymentStatusBlockProps {
  order: IMerchProductOrder;
}

const PaymentStatusBlock: React.FunctionComponent<
  IPaymentStatusBlockProps
> = props => {
  const o = props.order;
  const s = o.PaymentStatus;
  const bg =
    s === 'PAID'
      ? 'success'
      : s === 'WAITING' || s === 'POST_PAID'
      ? 'info'
      : 'secondary';
  const icon =
    s === 'PAID'
      ? 'check'
      : s === 'WAITING' || s === 'POST_PAID'
      ? 'clock'
      : 'info';

  return (
    <Badge
      bg={bg}
      variant="phoenix"
      iconPosition="end"
      className="fs-10"
      icon={<FeatherIcon icon={icon} size={12.8} className="ms-1" />}
    >
      {o.PaymentStatus}
    </Badge>
  );
};

export default PaymentStatusBlock;
