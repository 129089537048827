import './FilesPage.scss';
import React, { useEffect, useState } from 'react';
import { api } from '../../api/Api';
import Loader from '../../components/common/Loader/Loader';
import Button from '../../components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLink,
  faImagePortrait,
  faLink,
  faPen,
  faRefresh
} from '@fortawesome/free-solid-svg-icons';
import { generatePath, Link, useLoaderData } from 'react-router-dom';
import { IFileCollection } from '../../models/collections/IFileCollection';
import Pagination from '../../components/common/Pagination/Pagination';
import { Form } from 'react-bootstrap';
import { filesPerPageValues } from '../../loaders/FilesLoader/FilesLoader';
import PATHS from '../../paths';
import ProgressBar from '../../components/common/ProgressBar/ProgressBar';
import SearchBox from '../../components/common/SearchBox/SearchBox';

interface IFilesProps {}
interface IUploadingFile {
  idx: number;
  file: File;
  progress: number;
}

export const FilesPageSettings = {
  isUpdateNeeded: false
};

const trunc = (name: string, nl: number) => {
  if (name.length > nl) {
    const l = Math.ceil((nl - 3) / 2),
      r = Math.floor((nl - 3) / 2),
      reg = new RegExp(`^(.{${l}})(.+)(.{${r}})$` + '', 'g');
    return name.replace(reg, '$1...$3');
  }
  return name;
};

const FilesPage: React.FunctionComponent<IFilesProps> = () => {
  const fileDefaultCollection = useLoaderData() as IFileCollection;
  const [loading, setLoading] = useState<boolean>(false);

  const [filesCollection, setFilesCollection] = useState<IFileCollection>(
    fileDefaultCollection
  );

  // const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState<IUploadingFile[]>([]);
  const [key, setKey] = useState(0);

  // const navigate = useNavigate();
  const updateFromServer = (filesCollectionParams?: IFileCollection) => {
    filesCollectionParams = filesCollectionParams
      ? filesCollectionParams
      : filesCollection;
    setLoading(true);
    api.getCollection('File', filesCollectionParams).then(resp => {
      setFilesCollection(resp.itemsCollection as IFileCollection);
      setLoading(false);
    });
  };

  useEffect(() => {
    updateFromServer();
  }, []);

  // const deleteFiles = (files: IFile[]) => {
  //   const collectionParams: ICollectionParams = {
  //     pageNo: filesCollection.pageNo,
  //     perPage: filesCollection.perPage
  //   };
  //   api
  //     .delete(
  //       'File',
  //       files.map(s => s.ID),
  //       collectionParams
  //     )
  //     .then(resp => {
  //       setFilesCollection(resp.itemsCollection as IFileCollection);
  //     });
  // };

  const uploadFiles = (files: FileList) => {
    const uFiles: IUploadingFile[] = [];
    Array.from(files).forEach((f, idx) =>
      uFiles.push({ idx: idx, file: f, progress: 0 })
    );
    setUploadingFiles(uFiles);
    uFiles.forEach(uf => {
      api
        .uploadFile(uf.file, {
          onUploadProgress: progressEvent => {
            uf.progress = Number(progressEvent.progress);
            setKey(key + 1);
          }
        })
        .then(() => updateFromServer(filesCollection));
    });
  };

  const pageChange = (pageNo: number) => {
    updateFromServer({ ...filesCollection, pageNo: pageNo });
  };

  return (
    <div className="files-cont">
      <div>
        <div className="row">
          <div className="col-4">
            <h2 className="mb-4">My files</h2>
          </div>
          <div className="col-md-2">
            <SearchBox
              onChange={s =>
                s.length > 2
                  ? updateFromServer({
                      ...filesCollection,
                      textSearchFields: 'Title,Name',
                      textSearchValue: s
                    })
                  : null
              }
            />
          </div>
          <div className="col-6">
            <div className="ms-xxl-auto text-end">
              <Button
                variant="link"
                className="text-900 me-4 px-0"
                onClick={() => updateFromServer(filesCollection)}
              >
                <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
                Reload
              </Button>
              {/*<Button
                  variant="link"
                  className="text-900 me-4 px-0 text-danger"
                  onClick={() =>
                    confirm('Really delete selected files?')
                      ? deleteFiles(selectedFiles)
                      : null
                  }
                >
                  <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
                  Delete
                </Button>*/}
              {/*<Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>*/}
              <button type="button" className="btn btn-primary btn-upload">
                <input
                  type="file"
                  multiple={true}
                  accept="image/jpeg,image/png"
                  onChange={e => {
                    if (e.target.files) uploadFiles(e.target.files);
                  }}
                />
                <FontAwesomeIcon icon={faImagePortrait} className="fs-9 me-2" />
                Upload files
              </button>
            </div>
          </div>
        </div>
        {uploadingFiles.length > 0 && (
          <div className="alert alert-outline-primary uploading-files-cont">
            <div className="row">
              <div className="col-10 pt-2">
                <h5 className="alert-heading">Uploading files</h5>
              </div>
              <div className="col-2 text-end">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setUploadingFiles([])}
                ></button>
              </div>
            </div>
            <ul className="list-group mb-3">
              {uploadingFiles.map((f, idx) => {
                const name = trunc(f.file.name + '', 24);
                return (
                  <li
                    className="list-group-item bg-light-info"
                    key={`${f.file.name}-${idx}`}
                  >
                    <div className="row">
                      <div className="col-3">
                        <strong>{name}</strong>
                      </div>
                      <div className="col-7 pt-2">
                        <ProgressBar valuenow={f.progress * 100} />
                      </div>
                      <div className="col-2 text-end">{f.progress * 100} %</div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {loading && <Loader />}
        {!loading && (
          <>
            <div className="row files-header">
              <div className="col-md-6">
                <Pagination
                  {...filesCollection}
                  pageChange={pageChange}
                  paginationCount={5}
                />
              </div>
              <div className="col-md-3 text-center">
                <span className="btn btn-sm">
                  {filesCollection.totalCount} files
                </span>
              </div>
              <div className="col-md-2 text-end"></div>
              <div className="col-md-1 text-end">
                <Form.Select
                  className="mb-4"
                  onChange={e =>
                    updateFromServer({
                      ...filesCollection,
                      perPage: parseInt(e.target.value)
                    })
                  }
                >
                  {filesPerPageValues.map(v => (
                    <option value={v} key={v}>
                      {v} files
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="table-responsive files-table">
              {filesCollection.pageNo < 0 && <Loader />}
              {filesCollection.pageNo > 0 &&
                filesCollection.items.map((file, idx) => {
                  // const selectItem = (f: IFile, selected: boolean) =>
                  //   console.log(f);
                  const isSelected = false;
                  const name = trunc(file.Name + '', 16);
                  return (
                    <div
                      className={
                        'clfs-fi-cont' + (isSelected ? ' selected' : '')
                      }
                      key={`${file.ID}-${idx}`}
                    >
                      <div className={'card'}>
                        {/*<input
                        type="checkbox"
                        checked={isSelected}
                        onChange={e => selectItem(file, !isSelected)}
                      />*/}
                        <div className="clfs-fi-img-cont clfs-transp clfs-transp-8">
                          <img
                            src={file._props.thumb128}
                            alt={file.Name}
                            title={file.Name}
                          />
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">{name}</h5>
                          <a
                            className="file-edit-icon"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(file._props.publicUrl)
                                .then(() => {
                                  alert(
                                    `The link to the file "${file.Name}" copied to clipboard`
                                  );
                                });
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faLink}
                              className="ms-1 fs-9"
                            />
                          </a>
                          <Link
                            className="file-edit-icon"
                            to={file._props.publicUrl}
                            target="_blank"
                          >
                            <FontAwesomeIcon
                              icon={faExternalLink}
                              className="ms-1 fs-9"
                            />
                          </Link>
                          <Link
                            className="file-edit-icon"
                            to={generatePath(PATHS.FILES_EDIT, {
                              id: file.ID
                            })}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FilesPage;
