import './SubscriptionPlansBlock.scss';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/common/Loader/Loader';
import { ISubscriptionPlanCollection } from '../../../models/collections/ISubscriptionPlanCollection';
import SubscriptionItemBlock from './subscription-item-block/SubscriptionItemBlock';
import SubscriptionPlansLoader, {
  defaultCollection
} from '../../../loaders/SubscriptionPlansLoader/SubscriptionPlansLoader';
import { ISubscriptionFeatureCollection } from '../../../models/collections/ISubscriptionFeatureCollection';
import { api } from '../../../api/Api';

const defaultFeatureCollection = {
  pageNo: 1,
  perPage: 100,
  items: [],
  itemsCount: 0,
  totalCount: 0
} as ISubscriptionFeatureCollection;

const SubscriptionPlansBlock = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [plansCollection, setPlansCollection] =
    useState<ISubscriptionPlanCollection>(defaultCollection);
  const [featuresCollection, setFeaturesCollection] =
    useState<ISubscriptionFeatureCollection>(defaultFeatureCollection);

  const updateFromServer = () => {
    setLoading(true);
    api
      .getCollection('SubscriptionFeature', defaultFeatureCollection)
      .then(resp => {
        setFeaturesCollection(
          resp.itemsCollection as ISubscriptionFeatureCollection
        );
        SubscriptionPlansLoader().then(itemsCollection => {
          setPlansCollection(itemsCollection);
          setLoading(false);
        });
      });
  };

  useEffect(() => {
    updateFromServer();
  }, []);

  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            {/*<h3 className="h3">Subscription plans</h3>*/}
          </div>
          <div className="row">
            {plansCollection.items.map(plan => (
              <SubscriptionItemBlock
                key={plan.ID}
                plan={plan}
                selected={false}
                features={featuresCollection.items}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionPlansBlock;
