import './FilePreview.scss';
import React from 'react';
import IFile from '../../../../models/restful/IFile';

interface IFileViewProps {
  file: IFile;
}

const FilePreview: React.FunctionComponent<IFileViewProps> = (
  props: IFileViewProps
) => {
  const file = props.file;

  return (
    <div className="file-preview-cont">
      <p>
        <h4>Title:</h4>
        &nbsp;
        {file.Title}
      </p>
    </div>
  );
};

export default FilePreview;
