const PATHS = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SIGN_OUT: '/sign-out',
  ORDERS: '/orders',
  ORDER_VIEW: '/orders/view/:name',
  ORDER_EDIT: '/orders/edit/:name',
  PAYMENTS: '/payments',
  PAYMENT_VIEW: '/payments/view/:id',
  STORES: '/stores',
  STORE_VIEW: '/stores/view/:id',
  STORE_EDIT: '/stores/edit/:id',
  FILES: '/my-files',
  FILES_VIEW: '/my-files/view/:id',
  FILES_EDIT: '/my-files/edit/:id',
  TRANSACTION_SUCCESS: '/transaction/success/:hash',
  TRANSACTION_CANCELED: '/transaction/canceled/:hash'
};

export default PATHS;
