import './OrderForm.scss';
import React, { useEffect, useState } from 'react';
import IMerchProductOrder from '../../../models/restful/IMerchProductOrder';
import Products from './Products/Products';
import Shipping from './Shipping/Shipping';
import Confirm from './Confirm/Confirm';
import Designs from './Designs/Designs';
import Stepper from '../../../components/common/Stepper/Stepper';
import {
  confirmValid,
  designsValid,
  productsValid,
  shipmentValid
} from './OrderFormFunctions';
import { api } from '../../../api/Api';
import IMerchStore from '../../../models/restful/IMerchStore';
import IMerchProduct from '../../../models/restful/IMerchProduct';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import PATHS from '../../../paths';
import Button from '../../../components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { pay } from '../orders-table/OrdersFunctions';
import { useUser } from '../hooks/useUser';

interface IOrderFormProps {}

type TStep = 'products' | 'design' | 'shipping' | 'confirm' | 'view';

const OrderForm: React.FunctionComponent<IOrderFormProps> = () => {
  const { name } = useParams();
  const [order, setOrder] = useState<IMerchProductOrder>(
    {} as IMerchProductOrder
  );

  const navigate = useNavigate();

  const updateOrderFromServer = () => {
    api.read('MerchProductOrder', '' + name, 'Name').then(resp => {
      if (resp.itemsCollection?.itemsCount) {
        const isFirstCall = !order || !order.ID;
        const o = resp.itemsCollection?.items[0] as IMerchProductOrder;
        let defaultStep: TStep = step;
        if (productsValid(o)) defaultStep = 'design';
        if (designsValid(o)) defaultStep = 'shipping';
        if (shipmentValid(o)) defaultStep = 'confirm';
        setOrder(o);
        if (isFirstCall && defaultStep !== step) {
          setStep(defaultStep);
        }
      }
    });
  };

  useEffect(() => {
    updateOrderFromServer();
    api
      .getCollection('MerchStore', { perPage: 1000, pageNo: -1 })
      .then(resp => setStores(resp.itemsCollection?.items as IMerchStore[]));
    api
      .getCollection('MerchProduct', { perPage: 1000, pageNo: -1 })
      .then(resp =>
        setProducts(resp.itemsCollection?.items as IMerchProduct[])
      );
  }, []);

  const [step, setStep] = useState<TStep>('products');
  const [showRequired, setShowRequired] = useState<number>(0);
  const [stores, setStores] = useState<IMerchStore[]>([]);
  const [products, setProducts] = useState<IMerchProduct[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<IMerchProduct[]>([]);
  const { getUser } = useUser();
  const user = getUser();

  if (!order?.ID) return null;

  const getProgress = () => {
    if (step === 'products') return 1;
    if (step === 'design') return 34;
    if (step === 'shipping') return 67;
    if (step === 'confirm') return 99;
    return 0;
  };
  const getProgressStep = () => {
    if (step === 'products') return 1;
    if (step === 'design') return 2;
    if (step === 'shipping') return 3;
    if (step === 'confirm') return 4;
    return 1;
  };
  const setProgressStep = (idx: number) => {
    console.log(idx);
    console.log(showRequired);
    let st = '';
    if (idx === 1) {
      st = 'products';
    }
    if (idx === 2) {
      if (productsValid(order)) {
        if (showRequired > 0) setShowRequired(0);
        st = 'design';
      } else setShowRequired(1);
    }
    if (idx === 3) {
      if (designsValid(order)) {
        if (showRequired > 0) setShowRequired(0);
        st = 'shipping';
      } else setShowRequired(2);
    }
    if (idx === 4) {
      if (shipmentValid(order)) {
        if (showRequired > 0) setShowRequired(0);
        st = 'confirm';
      } else setShowRequired(3);
    }
    if (st) setStep(st as TStep);
  };

  const getButtons = () => {
    return (
      <div className="row justify-content-center">
        <div className="col-3 mb-3 pt-3">
          <button
            className="btn btn-info btn-sm"
            disabled={getProgressStep() <= 1}
            onClick={() =>
              getProgressStep() > 1
                ? setProgressStep(getProgressStep() - 1)
                : null
            }
          >
            Prev
          </button>
        </div>
        <div className="col-5 mb-3">
          <Stepper
            progress={getProgress()}
            steps={[
              {
                idx: 1,
                title: 'Products',
                hasError: !productsValid(order),
                onClick: () => setProgressStep(1)
              },
              {
                idx: 2,
                title: 'Designs',
                hasError: !designsValid(order),
                onClick: () => setProgressStep(2)
              },
              {
                idx: 3,
                title: 'Shipping',
                hasError: !shipmentValid(order),
                onClick: () => setProgressStep(3)
              },
              {
                idx: 4,
                title: 'Confirm',
                hasError: !confirmValid(order),
                onClick: () => setProgressStep(4)
              }
            ]}
          />
        </div>
        <div className="col-4 mb-3 pt-3 text-end">
          {getProgressStep() < 4 && (
            <button
              className="btn btn-info btn-sm"
              onClick={() =>
                getProgressStep() < 4
                  ? setProgressStep(getProgressStep() + 1)
                  : null
              }
            >
              Next
            </button>
          )}
          {!user.IsPostPaid && getProgressStep() === 4 && (
            <button
              className="btn btn-success btn-sm me-3"
              onClick={() => confirmAndPayOrder()}
            >
              Confirm and pay
            </button>
          )}
          {user.IsPostPaid && getProgressStep() === 4 && (
            <button
              className="btn btn-success btn-sm me-3"
              onClick={() => confirmAndPostOrder()}
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    );
  };

  const confirmAndPayOrder = () => {
    api.confirm([order]).then(() => {
      pay([order]);
    });
  };
  const confirmAndPostOrder = () => {
    api.confirm([order]).then(() => {
      navigate(generatePath(PATHS.ORDERS));
    });
  };

  return (
    <div className="order-form-cont">
      <div className="row">
        <div className="col-6">
          <h2 className="mb-4">Order {order.Name}</h2>
        </div>
        <div className="col-6">
          <div className="ms-xxl-auto text-end">
            <Button
              variant="link"
              className="text-900 me-4 px-0"
              onClick={() => updateOrderFromServer()}
            >
              <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
              Reload
            </Button>
            <Button
              variant="link"
              className="text-900 me-4 px-0"
              onClick={() => navigate(generatePath(PATHS.ORDERS))}
            >
              <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
              Save and close
            </Button>
            <Button
              variant="link"
              className="text-900 me-4 px-0"
              onClick={() =>
                getProgressStep() < 4
                  ? setProgressStep(getProgressStep() + 1)
                  : null
              }
            >
              <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
              Save
            </Button>
          </div>
        </div>
      </div>

      {getButtons()}
      {step === 'products' && (
        <Products
          order={order}
          setOrder={setOrder}
          products={products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          showRequired={showRequired === 1}
        />
      )}
      {step === 'design' && (
        <Designs
          order={order}
          stores={stores}
          products={products}
          showRequired={showRequired === 2}
          updateOrderFromServer={updateOrderFromServer}
        />
      )}
      {step === 'shipping' && (
        <Shipping
          order={order}
          shoi={order.ShipmentOrderItem}
          showRequired={showRequired === 3}
          updateOrderFromServer={updateOrderFromServer}
        />
      )}
      {step === 'confirm' && <Confirm order={order} />}
      {getButtons()}
    </div>
  );
};

export default OrderForm;
