import React from 'react';
import './Stepper.scss';

export interface IStepperStep {
  idx: number;
  title: string;
  hasError?: boolean;

  onClick(idx: number): void;
}

interface IStepperProps {
  progress: number;
  steps: IStepperStep[];
}

const Stepper: React.FunctionComponent<IStepperProps> = (
  props: IStepperProps
) => {
  return (
    <div className="steps-cont">
      <div className="steps">
        <progress
          className="stepper-progress"
          value={props.progress}
          max={100}
        />
        {props.steps.map((step, idx) => {
          const processedSteps = Math.floor(
            (props.progress * props.steps.length) / 100
          );
          let style = 'btn-';
          if (step.hasError && idx < processedSteps) style += 'danger';
          else {
            if (idx < processedSteps) style += 'success';
            if (idx === processedSteps) style += 'primary';
            if (idx > processedSteps) style += 'secondary';
          }
          if (idx < processedSteps) style += ' step-done';
          if (idx === processedSteps) style += ' step-active';
          if (idx > processedSteps) style += ' step-undone';

          return (
            <div className="step-item" key={idx}>
              <button
                className={`step-button text-center btn ${style}`}
                type="button"
                onClick={() => step.onClick(step.idx)}
              >
                {step.idx}
              </button>
              <div className="step-title">{step.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
