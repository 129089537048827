import React from 'react';
import './StoresActions.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faList,
  faPencil,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import IMerchStore from '../../../models/restful/IMerchStore';
import { api } from '../../../api/Api';
import { StoresPageSettings } from '../StoresPage';
import PATHS from '../../../paths';

interface IStoresActionsProps {
  store: IMerchStore;
  updateFromServer(): void;
}

const StoresActions: React.FunctionComponent<IStoresActionsProps> = props => {
  const store = props.store;
  const navigate = useNavigate();

  const duplicateStore = () => {
    api.create('MerchStore', { ...store, ID: 0 }).then(resp => {
      const st = resp.item as IMerchStore;
      StoresPageSettings.isUpdateNeeded = true;
      navigate(generatePath(PATHS.STORE_EDIT, { id: st.ID }));
    });
  };

  const deleteStore = () => {
    api.delete('MerchStore', [store.ID]).then(() => {
      props.updateFromServer();
    });
  };

  return (
    <>
      <Link
        className="btn btn-outline-secondary btn-sm store-action-btn"
        to={generatePath(PATHS.STORE_VIEW, { id: store.ID })}
      >
        <FontAwesomeIcon icon={faList} className="ms-1 fs-9" />
        &nbsp; View
      </Link>
      <Link
        className="btn btn-outline-primary btn-sm store-action-btn"
        to={generatePath(PATHS.STORE_EDIT, { id: store.ID })}
      >
        <FontAwesomeIcon icon={faPencil} className="ms-1 fs-9" />
        &nbsp; Edit
      </Link>
      <button
        className="btn btn-outline-success btn-sm store-action-btn"
        onClick={() =>
          window.confirm('Really copy store?') ? duplicateStore() : null
        }
      >
        <FontAwesomeIcon icon={faCopy} className="ms-1 fs-9" />
        &nbsp; Copy
      </button>
      <button
        className="btn btn-outline-danger btn-sm store-action-btn"
        onClick={() =>
          window.confirm('Really delete store?') ? deleteStore() : null
        }
      >
        <FontAwesomeIcon icon={faTrash} className="ms-1 fs-9" />
        &nbsp; Delete
      </button>
    </>
  );
};

export default StoresActions;
