import IMerchProductOrder from '../../../models/restful/IMerchProductOrder';
import { isPhoneValid } from './Shipping/Shipping';

export const productsValid = (order: IMerchProductOrder) => {
  return !!order?.ProductOrderItems?.length;
};

export const designsValid = (order: IMerchProductOrder) => {
  if (!order?.ProductOrderItems?.length) return false;
  let b = true;
  order.ProductOrderItems.map(oi => {
    b = !!oi.ArtworkID && !!oi.ProductJSON;
  });
  return b;
};

export const shipmentValid = (order: IMerchProductOrder) => {
  const shoi = order?.ShipmentOrderItem;
  if (!shoi) return false;
  return (
    shoi.FirstName &&
    shoi.LastName &&
    shoi.Email &&
    isPhoneValid(shoi.Phone) &&
    shoi.CountryName &&
    shoi.RegionName &&
    shoi.CityName &&
    shoi.PostalCode &&
    shoi.Address
  );
};

export const confirmValid = (order: IMerchProductOrder) => {
  let b = true;
  b = !!order?.Title;
  return b;
};
