import PATHS from '../paths';
import FilesPage from '../pages/my-files/FilesPage';
import FilesLoader from '../loaders/FilesLoader/FilesLoader';
import FileView from '../pages/my-files/FileView/FileView';
import FileForm from '../pages/my-files/FileForm/FileForm';

export const FILES_ROUTES = [
  {
    index: true,
    element: <FilesPage />,
    loader: () => FilesLoader()
  },
  {
    path: PATHS.FILES_VIEW,
    element: <FileView />
  },
  {
    path: PATHS.FILES_EDIT,
    element: <FileForm />
  }
];
