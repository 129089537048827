import IUser from '../../../models/IUser';

export const useUser = () => {
  const getUser = () => {
    return (JSON.parse(localStorage.getItem('User') + '') || {}) as IUser;
  };

  const setUser = (user: IUser) => {
    localStorage.setItem('User', JSON.stringify(user));
  };

  return {
    getUser,
    setUser
  };
};
