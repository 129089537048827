import './TransactionCanceledPage.scss';
import { generatePath, Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { api } from '../../../api/Api';
import { OrdersTablePageSettings } from '../../orders/orders-table/OrdersTablePage';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PATHS from '../../../paths';
import { PaymentsTableSettings } from '../../payments/Table/Table';

interface ITransactionCanceledPagePageProps {}

const TransactionCanceledPage: React.FunctionComponent<
  ITransactionCanceledPagePageProps
> = () => {
  const { hash } = useParams();

  useEffect(() => {
    api.transaction({ hash: `${hash}`, success: false }).then(() => {
      OrdersTablePageSettings.isUpdateNeeded = true;
      PaymentsTableSettings.isUpdateNeeded = true;
    });
  }, []);
  return (
    <div className="transaction-success-cont">
      <h3 className="text-center mb-3">Payment failed</h3>
      <h4 className="text-center mb-4">
        Square encountered
        <br />
        an unexpected error
        <br />
        Please try again or contact support
        <br />
        if the problem continues.
      </h4>
      <p className="text-center mb-4" style={{ fontSize: '120px' }}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="bg-white"
          style={{
            borderRadius: '50%'
          }}
        />
      </p>
      <div className="row pt-3">
        <div className="col-4"></div>
        <div className="col-4">
          <div className="d-grid gap-2">
            <Link
              to={generatePath(PATHS.ORDERS)}
              className="mb-3 btn btn-primary"
            >
              Show my orders
            </Link>
            <Link
              to={generatePath(PATHS.PAYMENTS)}
              className="mb-3 btn btn-primary"
            >
              Show my payments
            </Link>
          </div>
        </div>
        <div className="col-4"></div>
      </div>
    </div>
  );
};

export default TransactionCanceledPage;
