import './Table.scss';
import React, { useEffect, useState } from 'react';
import { IPaymentCollection } from '../../../models/collections/IPaymentCollection';
import ICollectionParams from '../../../models/collections/ICollectionParams';
import { api } from '../../../api/Api';
import Loader from '../../../components/common/Loader/Loader';
import Pagination from '../../../components/common/Pagination/Pagination';
import { Form } from 'react-bootstrap';
import PaymentStatusBlock from '../payment-status-block/PaymentStatusBlock';

interface ITableProps {}

const perPageValues = [10, 20, 50, 100];
const defaultPaymentCollection: IPaymentCollection = {
  perPage: perPageValues[0],
  pageNo: -1,
  sortColumn: 'Created',
  sortDirection: '-',
  totalCount: 0,
  itemsCount: 0,
  items: []
};

export const PaymentsTableSettings = {
  isUpdateNeeded: false
};

const Table: React.FunctionComponent<ITableProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentsCollection, setPaymentsCollection] =
    useState<IPaymentCollection>(defaultPaymentCollection);
  const updateFromServer = (collectionParams?: ICollectionParams) => {
    setLoading(true);
    api
      .getCollection(
        'Payment',
        collectionParams ? collectionParams : defaultPaymentCollection
      )
      .then(resp => {
        setLoading(false);
        setPaymentsCollection(resp.itemsCollection as IPaymentCollection);
      });
  };
  const pageChange = (pageNo: number) => {
    updateFromServer({ ...paymentsCollection, pageNo: pageNo });
  };

  if (PaymentsTableSettings.isUpdateNeeded) {
    PaymentsTableSettings.isUpdateNeeded = false;
    updateFromServer();
  }

  useEffect(() => {
    updateFromServer();
  }, []);

  return (
    <div className="table-responsive payments-table">
      {loading && <Loader />}
      {!loading && (
        <div>
          <div className="row payments-header">
            <div className="col-md-6">
              <Pagination
                {...paymentsCollection}
                pageChange={pageChange}
                paginationCount={5}
              />
            </div>
            <div className="col-md-2">
              {paymentsCollection.totalCount} payments
            </div>
            <div className="col-md-2 text-end"></div>
            <div className="col-md-2 text-end">
              <Form.Select
                className="mb-4"
                onChange={e =>
                  updateFromServer({
                    ...paymentsCollection,
                    perPage: parseInt(e.target.value)
                  })
                }
              >
                {perPageValues.map(v => (
                  <option value={v} key={v}>
                    per page {v}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <hr />
          <table className="phoenix-table fs-9 table">
            <thead>
              <tr>
                <th scope="col" className="text-start">
                  Orders
                </th>
                <th scope="col" className="text-end">
                  Sum
                </th>
                <th scope="col" className="text-start">
                  Date
                </th>
                <th scope="col" className="text-center">
                  Payment status
                </th>
                <th scope="col" className="text-start">
                  Transactions
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentsCollection.items.map((p, idx) => {
                let s = '';
                p.Transactions.map(
                  t => (s += `${t.Gateway.Title} - ${t.Status}`)
                );
                return (
                  <tr key={idx}>
                    <td className="text-start">{p.Details}</td>
                    <td className="text-end">{p.Sum}</td>
                    <td className="text-start">{p.Created}</td>
                    <td className="text-center">
                      <PaymentStatusBlock payment={p} />
                    </td>
                    <td className="text-start">{s}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Table;
