import './PaymentsPage.scss';
import React from 'react';
import Table from './Table/Table';

interface IPaymentsPageProps {}

const PaymentsPage: React.FunctionComponent<IPaymentsPageProps> = () => {
  return (
    <div className="payments-cont">
      <div>
        <div className="row">
          <div className="col-6">
            <h2 className="mb-4">Payments</h2>
          </div>
          <div className="col-6">
            <div className="ms-xxl-auto text-end">
              {/*<Link*/}
              {/*  className="text-900 me-4 px-0 text-success"*/}
              {/*  to={generatePath(PATHS.TRANSACTION_SUCCESS, {*/}
              {/*    hash: 'DhWPdeJO4QUuGQQZ5MKORfWux6ihUxrj'*/}
              {/*  })}*/}
              {/*>*/}
              {/*  Link to success*/}
              {/*</Link>*/}
              {/*<Link*/}
              {/*  className="text-900 me-4 px-0 text-danger"*/}
              {/*  to={generatePath(PATHS.TRANSACTION_CANCELED, {*/}
              {/*    hash: 'DhWPdeJO4QUuGQQZ5MKORfWux6ihUxrj'*/}
              {/*  })}*/}
              {/*>*/}
              {/*  Link to canceled*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
        <Table />
      </div>
    </div>
  );
};

export default PaymentsPage;
