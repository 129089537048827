import PATHS from '../paths';
import OrdersTablePage from '../pages/orders/orders-table/OrdersTablePage';
import OrdersLoader from '../loaders/OrdersLoader/OrdersLoader';
import OrderDetails from '../pages/orders/order-view/OrderDetails';
import OrderForm from '../pages/orders/order-edit/OrderForm';

export const ORDERS_ROUTES = [
  {
    index: true,
    element: <OrdersTablePage />,
    loader: () => OrdersLoader()
  },
  {
    path: PATHS.ORDER_VIEW,
    element: <OrderDetails />
  },
  {
    path: PATHS.ORDER_EDIT,
    element: <OrderForm />
  }
];
