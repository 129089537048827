import React, { ChangeEvent } from 'react';
import './DropZoneField.scss';

export type DropZoneFieldProps = {
  accept: string;
  acceptNice?: string;
  height?: string;
  dropzoneText?: string;
  acceptedText?: string;
  showButton?: boolean;
  buttonText?: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
};

class DropZoneField extends React.Component<DropZoneFieldProps, any> {
  render() {
    const { height, dropzoneText, acceptedText, onChange, accept, ...props } =
      this.props;

    return (
      <div
        className={
          'dropzone-field-cont' + (props.showButton ? ' buttoned' : '')
        }
        style={{ height: height ? height : '200px' }}
      >
        {!!props.showButton && (
          <div className="dropzone-field-btn-cont">
            <span
              className="btn btn-success btn-sm"
              onClick={e => {
                const elem = (
                  (e.target as HTMLDivElement).closest(
                    '.dropzone-field-cont'
                  ) as Element
                ).querySelector('input[type=file]');
                (elem as HTMLInputElement).click();
              }}
            >
              {props.buttonText}
            </span>
            {!!props.acceptNice && (
              <span className="text-muted">
                &nbsp;&nbsp;
                {acceptedText ? acceptedText : 'Accepted'}: &nbsp;
                {props.acceptNice}
              </span>
            )}
          </div>
        )}

        <div
          className="dropzone-field"
          onDragOver={e => {
            (
              (e.target as Element).closest('.dropzone-field') as Element
            ).classList.add('hover');
          }}
          onDragLeave={e => {
            (
              (e.target as Element).closest('.dropzone-field') as Element
            ).classList.remove('hover');
          }}
        >
          <span className="dropzone-field-text">{dropzoneText}</span>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              Default file input example
            </label>
            <input
              className="form-control"
              type="file"
              onChange={onChange}
              accept={accept}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DropZoneField;
