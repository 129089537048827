import Avatar from 'components/base/Avatar';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import PATHS from '../../../paths';
import { useUser } from '../../../pages/orders/hooks/useUser';
import { api } from '../../../api/Api';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const { getUser } = useUser();
  const user = getUser();

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={user.Avatar?._props?.publicUrl} size="xl" />
            <h6 className="text-black">
              {user.FirstName} {user.Surname}
            </h6>
          </div>
          <div style={{ height: '4rem' }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                <Nav.Item>
                  <Nav.Link href="/" className="px-3">
                    <FeatherIcon
                      icon="pie-chart"
                      size={16}
                      className="me-2 text-900"
                    />
                    <span className="text-1000">Dashboard</span>
                  </Nav.Link>
                </Nav.Item>

                {user.IsAdmin && (
                  <Nav.Item>
                    <Nav.Link
                      href={`${api.host}/admin`}
                      target="_blank"
                      className="px-3"
                    >
                      <FeatherIcon
                        icon="globe"
                        size={16}
                        className="me-2 text-900"
                      />
                      <span className="text-1000">Admin Area</span>
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>
        <Card.Footer className="p-0 border-top">
          <div className="px-3">
            <Link
              to={PATHS.SIGN_OUT}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sign out
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
