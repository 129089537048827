import React from 'react';
import './Pagination.scss';
import ICollection from '../../../models/collections/ICollection';

interface IPaginationProps extends ICollection {
  paginationCount: number;

  pageChange(pageNo: number): void;
}

const Pagination: React.FunctionComponent<IPaginationProps> = (
  props: IPaginationProps
) => {
  const { totalCount, perPage, pageNo, pageChange, paginationCount } = props;

  const totalPages = perPage > 0 ? Math.ceil(totalCount / perPage) : 1;
  const page = pageNo > totalPages ? totalPages : pageNo < 1 ? 1 : pageNo;
  const hasLessPages = page - paginationCount / 2 > 1;
  const hasMorePages = page + paginationCount / 2 <= totalPages;

  return (
    <span className="page-navigation">
      <ul className="pagination pagination-sm">
        <li className={'page-item' + (page <= 1 ? ' disabled' : '')}>
          <span
            className="page-link"
            onClick={() => (page > 1 ? pageChange(page - 1) : null)}
          >
            <span aria-hidden="true">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-left"
                className="svg-inline--fa fa-chevron-left "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                ></path>
              </svg>
            </span>
          </span>
        </li>
        {hasLessPages && (
          <li className="page-item">
            <span
              className="page-link"
              onClick={() => pageChange(page - Math.floor(paginationCount / 2))}
            >
              ...
            </span>
          </li>
        )}
        {[...Array(paginationCount).keys()].map((v, i) => {
          const p = page - Math.floor(paginationCount / 2) + v;
          if (p < 1 || p > totalPages) return null;
          return (
            <li key={i} className={'page-item' + (page === p ? ' active' : '')}>
              <span className="page-link" onClick={() => pageChange(p)}>
                {p}
              </span>
            </li>
          );
        })}
        {hasMorePages && (
          <li className="page-item">
            <span
              className="page-link"
              onClick={() => pageChange(page + Math.floor(paginationCount / 2))}
            >
              ...
            </span>
          </li>
        )}
        <li className={'page-item' + (page >= totalPages ? ' disabled' : '')}>
          <span
            className="page-link"
            onClick={() => (page < totalPages ? pageChange(page + 1) : null)}
          >
            <span aria-hidden="true">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-right"
                className="svg-inline--fa fa-chevron-right "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                ></path>
              </svg>
            </span>
          </span>
        </li>
      </ul>
    </span>
  );
};

export default Pagination;
