import './Designs.scss';
import React, { useState } from 'react';
import Design from './Design/Design';
import { api } from '../../../../api/Api';
import IMerchProductOrder from '../../../../models/restful/IMerchProductOrder';
import IMerchStore from '../../../../models/restful/IMerchStore';
import IMerchProduct from '../../../../models/restful/IMerchProduct';
import { Form } from 'react-bootstrap';

interface IOrderItemProps {
  order: IMerchProductOrder;
  stores: IMerchStore[];
  products: IMerchProduct[];
  showRequired: boolean;
  updateOrderFromServer(): void;
}

const Designs: React.FunctionComponent<IOrderItemProps> = (
  props: IOrderItemProps
) => {
  const [key, setKey] = useState(0);
  const updateOrder = (order: IMerchProductOrder) => {
    api.update('MerchProductOrder', order);
  };

  return (
    <div className="order-items-cont">
      <div className="mb-3 row">
        <div className="col-4">
          <label htmlFor="" className="col-sm-2 col-form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="My new order"
            defaultValue={props.order.Title}
            onChange={e => {
              props.order.Title = e.currentTarget.value;
              updateOrder(props.order);
            }}
          />
        </div>
        <div className="col-4">
          <label htmlFor="" className="col-sm-2 col-form-label">
            Store
          </label>

          <Form.Select
            className="mb-4"
            defaultValue={props.order.StoreID}
            onChange={e => {
              const id = parseInt(e.target.value);
              const st = props.stores.find(s => s.ID === id);
              props.order.StoreID = st ? st.ID : 0;
              props.order.Store = st
                ? st
                : ({
                    ID: 0,
                    Title: 'Choose store'
                  } as IMerchStore);
              updateOrder(props.order);
              setKey(key + 1);
            }}
          >
            <option value={0} key={0}>
              Choose store
            </option>
            {props.stores.map(store => (
              <option value={store.ID} key={store.ID}>
                {store.Title}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="col-4">
          <label htmlFor="ExternalID" className="col-sm-2 col-form-label">
            ExternalID
          </label>
          <input
            id="ExternalID"
            type="text"
            className="form-control"
            placeholder="My External ID"
            defaultValue={props.order.ExternalID}
            onChange={e => {
              props.order.ExternalID = e.currentTarget.value;
              updateOrder(props.order);
            }}
          />
        </div>
      </div>
      {props.order.ProductOrderItems.map((oi, idx) => (
        <Design
          orderItem={oi}
          key={idx}
          showRequired={props.showRequired}
          products={props.products}
          updateOrderFromServer={props.updateOrderFromServer}
        />
      ))}
    </div>
  );
};
export default Designs;
