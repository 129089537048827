import {
  faFileExport,
  faPlus,
  faRefresh
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import { IProductOrderCollection } from '../../../models/collections/IProductOrderCollection';
import IMerchProductOrder from '../../../models/restful/IMerchProductOrder';
import ICollectionParams from '../../../models/collections/ICollectionParams';
import { api } from '../../../api/Api';
import { useState } from 'react';
import PATHS from '../../../paths';
import OrdersLoader from '../../../loaders/OrdersLoader/OrdersLoader';
import Table from './orders-table/Table';

export const OrdersTablePageSettings = {
  isUpdateNeeded: false
};

const OrdersTablePage = () => {
  const ordersDefaultCollection = useLoaderData() as IProductOrderCollection;
  const [collection, setCollection] = useState<IProductOrderCollection>(
    ordersDefaultCollection
  );
  const navigate = useNavigate();

  const updateFromServer = (collectionParams?: ICollectionParams) => {
    const colPars = collectionParams ? collectionParams : collection;
    OrdersLoader(colPars).then(itemsCollection => {
      setCollection(itemsCollection as IProductOrderCollection);
    });
  };

  if (OrdersTablePageSettings.isUpdateNeeded) {
    OrdersTablePageSettings.isUpdateNeeded = false;
    updateFromServer(collection);
  }

  return (
    <div>
      <div className="mb-9">
        <div className="row">
          <div className="col-6">
            <h2 className="mb-4">Orders</h2>
          </div>
          <div className="col-6">
            <div className="ms-xxl-auto text-end">
              <Button
                variant="link"
                className="text-900 me-4 px-0"
                onClick={() => updateFromServer(collection)}
              >
                <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
                Reload
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Export
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  api.createOrder({} as IMerchProductOrder).then(resp => {
                    navigate(
                      generatePath(PATHS.ORDER_EDIT, { name: resp.item.Name })
                    );
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Add order
              </Button>
            </div>
          </div>
        </div>

        {/*<FilterTab tabItems={tabItems} className="mb-2" />*/}

        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <Table />
        </div>
      </div>
    </div>
  );
};

export default OrdersTablePage;
